import React, { useState } from 'react';
import { AiOutlineCloudUpload } from "react-icons/ai";
import { useSelector } from 'react-redux';
import { addVoiceOver, getUploadData, removeVoiceOver, uploadFileData } from '../../../../actions/storyAction';
import swal from 'sweetalert';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import MusicPlayer from './MusicPlayer';

const UploadAudio = ({ selectedSlideIndex }) => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const [data, setData] = useState([])
    const [loader, setLoader] = useState(false)
    const [percent, setPercent] = useState(0)
    const [currentMusic, setCurrentMusic] = useState("")
    const [state, setState] = useState({
        pickedMusic: -1,
    })

    const onInputFile = (e) => {
        const config = {
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded * 100) / total);
                setPercent(percent)
            },
            headers: {
                "Content-Type": "application/json",
                'Authorization': auth.token
            },
        }
        let allowedExt = ['audio/mpeg'];
        let allowedSize = 5000000
        if (allowedExt.includes(e.target.files[0].type)) {
            if (e.target.files[0].size < allowedSize) {
                setLoader(true)
                const formData = new FormData()
                formData.append('upload_type', "voiceover")
                formData.append('file', e.target.files[0])
                formData.append('user_id', auth.user.id)

                dispatch(uploadFileData(formData, config, setLoader, setPercent, fetchFiles))

            } else {
                swal("Oops!", "Max allowed size for VoiceOver is 5MB");
            }
        }
        else {
            swal("Oops!", "You have Selected Invalid File Type");
        }
    }

    const fetchFiles = () => {
        let obj = {
            type: "voiceover"
        }
        dispatch(getUploadData(obj, setData))
    }

    const handleVoiceOver = (obj, index) => {
        if (index === state.pickedMusic) {
            setState({
                ...state,
                pickedMusic: -1
            })
            dispatch(removeVoiceOver(selectedSlideIndex))
        } else {
            setState({
                ...state,
                pickedMusic: index
            })
            dispatch(addVoiceOver(obj, selectedSlideIndex))
        }
    }

    useEffect(() => {
        fetchFiles()
    }, [])

    return (
        <div className='videoOpt'>
            <div className='scrollDiv scrollDiv-3'>
                <div className="compactUpload mb-3">
                    <div>
                        <div><AiOutlineCloudUpload /></div>
                        <p>Upload Audio (Max Size 5MB)</p>
                    </div>
                    <input
                        type="file"
                        onChange={(e) => onInputFile(e)}
                    />
                </div>
                {loader ?
                    <div className="progress mt-3">
                        <div
                            className="progress-bar"
                            role="progressbar"
                            style={{
                                width: `${percent}%`,
                                color: "black",
                                fontWeight: 500
                            }}
                            aria-valuenow={`${percent}`}
                            aria-valuemin="0"
                            aria-valuemax="100"
                        >
                            {percent}%
                        </div>
                    </div>


                    : ""}
                <hr />
                <div className='imageSelect'>
                    <ul>
                        {data.length > 0 ?
                            data.map((curElem, index) => {
                                let obj = {
                                    url: curElem.url,
                                    title: curElem.name,
                                    id: curElem.id
                                }
                                return (
                                    <li
                                        key={index}
                                        onClick={() => handleVoiceOver(obj, index)}
                                    >
                                        <MusicPlayer
                                            curElem={obj}
                                            currentMusic={currentMusic}
                                            setCurrentMusic={setCurrentMusic}
                                            type="upload"
                                            check={state.pickedMusic}
                                            index={index}
                                            fetchFiles={fetchFiles}
                                        />
                                    </li>
                                )
                            })
                            : ""}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default UploadAudio;
