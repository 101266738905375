import React, { useState } from "react";
import wordpress from "../../images/round-icon-wordpress.png";
import download from "../../images/round-icon-download.png";
import importIcon from "../../images/round-icon-import.png"
import customize from "../../images/round-icon-customize.png";
import Rebranding from "./Rebranding";
import youtubeimg from '../../images/youtubeimg.jpg';
import YoutubeKeySearch from "./YoutubeKeySearch";
import modalCloseIcon from '../../images/modal-close.png';
import { appName } from "../../global/global";

const UpgradesContent = () => {
   const [playing, setPlaying] = useState(false);
   const [frameUrl, setFrameUrl] = useState();
   const addFrameUrl = () => {
      setPlaying(true);
      setFrameUrl('https://player.vimeo.com/video/514577280?');
   }
   const closePop = () => {
      setPlaying(false);
      setFrameUrl('');
   }
   return (
      <>
         <div className="col-lg-8 tabRight">
            <div className="tabCont-right">
               <div className="tab-content" id="v-pills-tabContent">
                  <div className="tab-pane fade show active">
                     <div className="tabepaneIn">
                        <h2 className="tabTitle">Welcome</h2>
                        <div className="tabepaneIn-main">
                           <div className="tabepaneIn-cont">
                              <h3>Welcome</h3>
                              <p>To access your upgrades, click any of the available options on the left side menu</p>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="tab-pane fade" id="v-pills-wlrebranding" role="tabpanel" aria-labelledby="v-pills-wlrebranding-tab" >
                     <div className="tabepaneIn">
                        <h2 className="tabTitle">Rebranding</h2>
                        <div className="tabepaneIn-main">
                           <div className="tabepaneIn-cont">
                              <Rebranding />
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="tab-pane fade" id="v-pills-fbtemplate" role="tabpanel" aria-labelledby="v-pills-fbtemplate-tab">
                     <div className="tabepaneIn">
                        <h2 className="tabTitle">FB Templates</h2>
                        <div className="tabepaneIn-main">
                           <div className="tabepaneIn-cont">
                              <h3>Facebook Ads Templates</h3>
                              <div className="upgrade-para">
                                 <p className="text-center ">
                                    Download this done-for-you Facebook and Instagram ad creatives
                                    and ad copy templates that can easily be customized to be used
                                    for any niche
                                 </p>
                              </div>
                           </div>
                           <div className="tabepaneIn-cont mt-4">
                              <h3>Download your Facebook Ads Templates here</h3>
                              <a
                                 href="https://s3-us-west-2.amazonaws.com/adsviser3/library/adpack1.zip"
                                 target="_blank"
                              >
                                 <button className="demoLink btn-block mt-0">Download the Templates</button>
                              </a>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div
                     className="tab-pane fade"
                     id="v-pills-vidoeflix"
                     role="tabpanel"
                     aria-labelledby="v-pills-vidoeflix-tab"
                  >
                     <h5 className="text-white">Video Flix</h5>
                     <div className="upgrade-content">
                        <div className="row">
                           <div className="col-md-8 mx-auto">
                              <div className="upgrade-box-content  text-white  ">
                                 <div className="upgrade-heading">
                                    <h6>VideoFLIX - Netflix Style Video Site Builder</h6>
                                 </div>
                                 <div className="upgrade-para">
                                    <p className="text-center ">
                                       VideoFLIX is a subscription-based video content management app
                                       built on cloud. Using VideoFLIX you will be able to create
                                       your own video content sharing website similar to NetFlix,
                                       HBO-Go, DisneyPLUS etc; sharing your own or others unique
                                       video content as channels or series. You will be able to
                                       create free, one-time or monthly payment plans for your
                                       customers to access your video content and build your video
                                       content subscription business!
                                    </p>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="row  mt-5">
                           <div className="col-md-6">
                              <div className="upgrade-box-content text-center p-3 text-white">
                                 <a
                                    href="https://abhibonus.s3-us-west-2.amazonaws.com/VideoFlix/VideoFlix_2.2.zip"
                                    target="_blank"
                                 >
                                    <button className="btn-change7">Download VideoFlix</button>
                                 </a>
                              </div>
                           </div>
                           <div className="col-md-6">
                              <div className="upgrade-box-content text-center p-3 text-white">
                                 <a
                                    href="https://abhibonus.s3-us-west-2.amazonaws.com/VideoFlix/VideoFlix+Training+Docs+updated.zip"
                                    target="_blank"
                                 >
                                    <button className="btn-change7">Download Tutorial Docs</button>
                                 </a>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="tab-pane fade" id="v-pills-udamy" role="tabpanel" aria-labelledby="v-pills-udamy-tab">
                     <div className="tabepaneIn">
                        <h2 className="tabTitle">Acadeable</h2>
                        <div className="tabepaneIn-main">
                           <div className="tabepaneIn-cont">
                              <h3>Acadeable</h3>
                              <div className="upgrade-para">
                                 <p className="text-center ">
                                    Download Udemy Style Website to showcase your courses. Instant
                                    Access to Udemy Style Website Builder to Sell Courses
                                 </p>
                              </div>
                              <a
                                 href="https://acadeable.s3.amazonaws.com/Acadeable_files.zip"
                                 target="_blank"
                              >
                                 <button className="demoLink btn-block">Download Setup</button>
                              </a>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div
                     className="tab-pane fade"
                     id="v-pills-dfycourse"
                     role="tabpanel"
                     aria-labelledby="v-pills-dfycourse-tab"
                  >
                     <h5 className="text-white">DFY Course</h5>
                     <div className="upgrade-content">
                        <div className="row">
                           <div className="col-md-8 mx-auto">
                              <div className="upgrade-box-content  text-white  ">
                                 <div className="upgrade-heading">
                                    <h6 className="m-0">DFY Courses</h6>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="row mt-5">
                           <div className="col-md-6">
                              <div className="upgrade-box-content text-center p-3 text-white">
                                 <a
                                    href="https://coursereel-app.s3-us-west-2.amazonaws.com/CourseEngagementHacks.zip"
                                    target="_blank"
                                 >
                                    <button className="btn-change7">Download DFY Course 1</button>
                                 </a>
                              </div>
                           </div>
                           <div className="col-md-6">
                              <div className="upgrade-box-content text-center p-3 text-white">
                                 <a
                                    href="https://acadeable.s3.amazonaws.com/dfycoursex196/HealthyBoundaries_GOLD.zip"
                                    target="_blank"
                                 >
                                    <button className="btn-change7">Download DFY Course 11</button>
                                 </a>
                              </div>
                           </div>
                        </div>
                        <div className="row mt-5">
                           <div className="col-md-6">
                              <div className="upgrade-box-content text-center p-3 text-white">
                                 <a
                                    href="https://coursereel-app.s3-us-west-2.amazonaws.com/EcommerceWithWoocommerce.zip"
                                    target="_blank"
                                 >
                                    <button className="btn-change7">Download DFY Course 2</button>
                                 </a>
                              </div>
                           </div>
                           <div className="col-md-6">
                              <div className="upgrade-box-content text-center p-3 text-white">
                                 <a
                                    href="https://acadeable.s3.amazonaws.com/dfycoursex196/GOLD-WorkFromHome.zip"
                                    target="_blank"
                                 >
                                    <button className="btn-change7">Download DFY Course 12</button>
                                 </a>
                              </div>
                           </div>
                        </div>
                        <div className="row mt-5">
                           <div className="col-md-6">
                              <div className="upgrade-box-content text-center p-3 text-white">
                                 <a
                                    href="https://coursereel-app.s3-us-west-2.amazonaws.com/GoogleAdsMastery.zip"
                                    target="_blank"
                                 >
                                    <button className="btn-change7">Download DFY Course 3</button>
                                 </a>
                              </div>
                           </div>
                           <div className="col-md-6">
                              <div className="upgrade-box-content text-center p-3 text-white">
                                 <a
                                    href="https://acadeable.s3.amazonaws.com/dfycoursex196/GOLD-PersonalBrandingBlueprint.zip"
                                    target="_blank"
                                 >
                                    <button className="btn-change7">Download DFY Course 13</button>
                                 </a>
                              </div>
                           </div>
                        </div>
                        <div className="row mt-5">
                           <div className="col-md-6">
                              <div className="upgrade-box-content text-center p-3 text-white">
                                 <a
                                    href="https://coursereel-app.s3-us-west-2.amazonaws.com/HowToUseWebinarsForYourBusiness.zip"
                                    target="_blank"
                                 >
                                    <button className="btn-change7">Download DFY Course 4</button>
                                 </a>
                              </div>
                           </div>
                           <div className="col-md-6">
                              <div className="upgrade-box-content text-center p-3 text-white">
                                 <a
                                    href="https://acadeable.s3.amazonaws.com/dfycoursex196/FacebookAdSecrets.zip"
                                    target="_blank"
                                 >
                                    <button className="btn-change7">Download DFY Course 14</button>
                                 </a>
                              </div>
                           </div>
                        </div>
                        <div className="row mt-5">
                           <div className="col-md-6">
                              <div className="upgrade-box-content text-center p-3 text-white">
                                 <a
                                    href="https://coursereel-app.s3-us-west-2.amazonaws.com/IMForNewbiesDeluxe.zip"
                                    target="_blank"
                                 >
                                    <button className="btn-change7">Download DFY Course 5</button>
                                 </a>
                              </div>
                           </div>
                           <div className="col-md-6">
                              <div className="upgrade-box-content text-center p-3 text-white">
                                 <a
                                    href="https://acadeable.s3.amazonaws.com/dfycoursex196/EmailMarketingBasicsVideo.zip"
                                    target="_blank"
                                 >
                                    <button className="btn-change7">Download DFY Course 15</button>
                                 </a>
                              </div>
                           </div>
                        </div>
                        <div className="row mt-5">
                           <div className="col-md-6">
                              <div className="upgrade-box-content text-center p-3 text-white">
                                 <a
                                    href="https://coursereel-app.s3-us-west-2.amazonaws.com/ModernPodcastingUp.zip"
                                    target="_blank"
                                 >
                                    <button className="btn-change7">Download DFY Course 6</button>
                                 </a>
                              </div>
                           </div>
                           <div className="col-md-6">
                              <div className="upgrade-box-content text-center p-3 text-white">
                                 <a
                                    href="https://acadeable.s3.amazonaws.com/dfycoursex196/EcommerceWithWoocommerce.zip"
                                    target="_blank"
                                 >
                                    <button className="btn-change7">Download DFY Course 16</button>
                                 </a>
                              </div>
                           </div>
                        </div>
                        <div className="row mt-5">
                           <div className="col-md-6">
                              <div className="upgrade-box-content text-center p-3 text-white">
                                 <a
                                    href="https://coursereel-app.s3-us-west-2.amazonaws.com/SimpleSocialMediaContent.zip"
                                    target="_blank"
                                 >
                                    {" "}
                                    <button className="btn-change7">Download DFY Course 7</button>
                                 </a>
                              </div>
                           </div>
                           <div className="col-md-6">
                              <div className="upgrade-box-content text-center p-3 text-white">
                                 <a
                                    href="https://acadeable.s3.amazonaws.com/dfycoursex196/DealClosingSecrets.zip"
                                    target="_blank"
                                 >
                                    {" "}
                                    <button className="btn-change7">Download DFY Course 17</button>
                                 </a>
                              </div>
                           </div>
                        </div>
                        <div className="row mt-5">
                           <div className="col-md-6">
                              <div className="upgrade-box-content text-center p-3 text-white">
                                 <a
                                    href="https://coursereel-app.s3-us-west-2.amazonaws.com/SimpleSocialMediaContent.zip"
                                    target="_blank"
                                 >
                                    {" "}
                                    <button className="btn-change7">Download DFY Course 8</button>
                                 </a>
                              </div>
                           </div>
                           <div className="col-md-6">
                              <div className="upgrade-box-content text-center p-3 text-white">
                                 <a
                                    href="https://acadeable.s3.amazonaws.com/dfycoursex196/CourseEngagementHacks.zip"
                                    target="_blank"
                                 >
                                    <button className="btn-change7">Download DFY Course 18</button>
                                 </a>
                              </div>
                           </div>
                        </div>
                        <div className="row mt-5">
                           <div className="col-md-6">
                              <div className="upgrade-box-content text-center p-3 text-white">
                                 <a
                                    href="https://coursereel-app.s3-us-west-2.amazonaws.com/TIKTOKADSMASTERY.zip"
                                    target="_blank"
                                 >
                                    <button className="btn-change7">Download DFY Course 9</button>
                                 </a>
                              </div>
                           </div>
                           <div className="col-md-6">
                              <div className="upgrade-box-content text-center p-3 text-white">
                                 <a
                                    href="https://acadeable.s3.amazonaws.com/dfycoursex196/ClicksAndTraffic.zip"
                                    target="_blank"
                                 >
                                    <button className="btn-change7">Download DFY Course 19</button>
                                 </a>
                              </div>
                           </div>
                        </div>
                        <div className="row mt-5">
                           <div className="col-md-6">
                              <div className="upgrade-box-content text-center p-3 text-white">
                                 <a
                                    href="https://coursereel-app.s3-us-west-2.amazonaws.com/UdemyForRecurringIncome.zip"
                                    target="_blank"
                                 >
                                    <button className="btn-change7">Download DFY Course 10</button>
                                 </a>
                              </div>
                           </div>
                           <div className="col-md-6">
                              <div className="upgrade-box-content text-center p-3 text-white">
                                 <a
                                    href="https://acadeable.s3.amazonaws.com/dfycoursex196/AntiAnexietyFormulaVideoUp.zip"
                                    target="_blank"
                                 >
                                    <button className="btn-change7">Download DFY Course 20</button>
                                 </a>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="tab-pane fade" id="v-pills-client" role="tabpanel" aria-labelledby="v-pills-client-tab">
                     <div className="tabepaneIn">
                        <h2 className="tabTitle">Client Contract</h2>
                        <div className="tabepaneIn-main">
                           <div className="tabepaneIn-cont">
                              <h3>Client Contract</h3>
                              <div className="upgrade-para">
                                 <p className="text-center ">
                                    Download our "Client Contract" template. This is a docx file,
                                    works best with MS Word. Edit the doc and add your business
                                    name, address and other details. Fill the empty blank space
                                    with your service, eg Video Marketing, Local Reputation
                                    Management, Social Media Marketing, Design &amp; Creatives,
                                    Ads Management and so on.
                                 </p>
                              </div>
                           </div>
                           <div className="tabepaneIn-cont-alt">
                              <div className="upgrade-content">
                                 <div className="row">
                                    <div className="col-xl-4 mb-2">
                                       <div className="upgrade-box-content text-center  text-white">
                                          <div className="upgrade-heading">
                                             <h6>Brief Pitch Template</h6>
                                          </div>
                                          <div className="upgrade-para p-3">
                                             <a
                                                href="https://misctrainingvideos.s3.amazonaws.com/Client+Contract/Client-Brief-Form.doc"
                                                target="_blank"
                                             >
                                                <button className="demoLink btn-block mt-0">Download</button>
                                             </a>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="col-xl-4  mb-2">
                                       <div className="upgrade-box-content text-center  text-white">
                                          <div className="upgrade-heading">
                                             <h6>Service Agreement</h6>
                                          </div>
                                          <div className="upgrade-para p-3">
                                             <a
                                                href="https://misctrainingvideos.s3.amazonaws.com/Client+Contract/Services+Agreement.doc"
                                                target="_blank"
                                             >
                                                <button className="demoLink btn-block mt-0">Download</button>
                                             </a>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="col-xl-4  mb-2">
                                       <div className="upgrade-box-content text-center  text-white">
                                          <div className="upgrade-heading">
                                             <h6>Meeting Setup Template</h6>
                                          </div>
                                          <div className="upgrade-para p-3">
                                             <a
                                                href="https://misctrainingvideos.s3.amazonaws.com/Client+Contract/Meeting-Doc.doc"
                                                target="_blank"
                                             >
                                                <button className="demoLink btn-block mt-0">Download</button>
                                             </a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="tab-pane fade" id="v-pills-reseller" role="tabpanel" aria-labelledby="v-pills-reseller-tab">
                     <div className="tabepaneIn">
                        <h2 className="tabTitle">Reseller License</h2>
                        <div className="tabepaneIn-main">
                           <div className="tabepaneIn-cont">
                              <h3>Reseller License</h3>
                              <div className="upgrade-para">
                                 <p className="text-center ">
                                    Go to JVZoo Dashboard and request your affiliate link. Once
                                    you've requested, submit a ticket with your {appName} Business
                                    transaction ID, affiliate ID and email address for our team to
                                    activate your reseller license. Please allow upto 2 working
                                    days.
                                 </p>
                              </div>
                              <div className="row   mt-5">
                                 <div className="col-md-6">
                                    <div className="upgrade-box-content text-center  text-white">
                                       <div className="">
                                          <a
                                             href="https://www.jvzoo.com/affiliate/affiliateinfonew/index/387813"
                                             target="_blank"
                                          >
                                             <button className="demoLink btn-block mt-0">
                                                Request Affiliate Link
                                             </button>
                                          </a>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-md-6">
                                    <div className="upgrade-box-content text-center  text-white">
                                       <div className="">
                                          <a href="https://support.vineasx.com/" target="_blank">
                                             <button className="demoLink btn-block mt-0">Raise Support Ticket</button>
                                          </a>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="tab-pane fade" id="v-pills-webhosting" role="tabpanel" aria-labelledby="v-pills-webhosting-tab">
                     <div className="tabepaneIn">
                        <h2 className="tabTitle">Web Hosting</h2>
                        <div className="tabepaneIn-main">
                           <div className="tabepaneIn-cont">
                              <h3>Web Hosting</h3>
                              <div className="upgrade-para">
                                 <p className="text-center ">
                                    Please raise a support ticket with your {appName} purchase
                                    transaction ID, your domain name that you currently own and
                                    wish to use with the hosting, email address, your full name
                                    and a note about how you would be using the webhosting for
                                    your business.
                                 </p>
                              </div>
                              <a href="https://support.vineasx.com/" target="_blank">
                                 <button className="demoLink btn-block">Raise a Ticket</button>
                              </a>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="tab-pane fade" id="v-pills-videocommercial" role="tabpanel" aria-labelledby="v-pills-videocommercial-tab">
                     <div className="tabepaneIn">
                        <h2 className="tabTitle">Video Commercial</h2>
                        <div className="tabepaneIn-main">
                           <div className="tabepaneIn-cont">
                              <h3>Video Commercial</h3>
                              <div className="upgrade-para">
                                 <p className="text-center ">
                                    6X Stunning &amp; Compelling Video Commercials
                                 </p>
                              </div>
                              <a
                                 href="https://abhibonus.s3-us-west-2.amazonaws.com/6X+Stunning+%26+Compelling+Video+Commercials+.zip"
                                 target="_blank"
                              >
                                 <button className="demoLink btn-block">Download Videos</button>
                              </a>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="tab-pane fade" id="v-pills-videopromo" role="tabpanel" aria-labelledby="v-pills-videopromo-tab">
                     <div className="tabepaneIn">
                        <h2 className="tabTitle">Video Promos</h2>
                        <div className="tabepaneIn-main">
                           <div className="tabepaneIn-cont">
                              <h3>Video Promos</h3>
                              <div className="upgrade-para">
                                 <p className="text-center ">
                                    Open a ticket with the following details per video:
                                 </p>
                                 <ol>
                                    <li>Your {appName} credentials</li>
                                    <li>Permission to access your account</li>
                                    <li>
                                       What is the purpose of this video that you wish to create?
                                    </li>
                                    <li>Name of the template you wish to use</li>
                                    <li>Number of the template you wish to use</li>
                                    <li>Screenshot of the template you wish to use</li>
                                    <li>
                                       Content for the video. Please click on the EDIT option for
                                       the template you wish to use and share with us all the text
                                       content, logo, music file or Text-to-speech content along
                                       with images/video clips you wish to use in your videos.
                                    </li>
                                 </ol>
                              </div>
                              <a href="https://support.vineasx.com/" target="_blank">
                                 {" "}
                                 <button className="demoLink btn-block">Raise a Ticket</button>
                              </a>
                           </div>
                           <div className="tabepaneIn-cont mt-4">
                              <h3>Note</h3>
                              <div className="upgrade-para">
                                 <p className="text-center ">
                                    we have the rights to deny a request if it does not match with
                                    the video template requirements. Video creation can take up to
                                    5-6 working days. The video will be available inside your
                                    {appName} Account.
                                 </p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="tab-pane fade" id="v-pills-dfylead" role="tabpanel" aria-labelledby="v-pills-dfylead-tab">
                     <div className="tabepaneIn">
                        <h2 className="tabTitle">DFY Lead Magnets</h2>
                        <div className="tabepaneIn-main">
                           <div className="tabepaneIn-cont">
                              <h3>DFY Lead Magnets</h3>
                              <a
                                 href="https://s3-us-west-2.amazonaws.com/virtualpioneer.net/Sendiio/100.zip"
                                 target="_blank"
                              >
                                 <button className="demoLink btn-block mt-0" type="button">
                                    Download
                                 </button>
                              </a>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="tab-pane fade" id="v-pills-agency" role="tabpanel" aria-labelledby="v-pills-agency-tab">
                     <div className="tabepaneIn">
                        <h2 className="tabTitle">Agency</h2>
                        <div className="tabepaneIn-main">
                           <div className="tabepaneIn-cont">
                              <h3>Agency</h3>
                              <div className="row">
                                 <div className="col-md-12 mx-auto">
                                    <div className="upgrade-box-content p-2 text-white  ">
                                       <div className="row">
                                          <div className="col-md-2 pr-0 text-center ">
                                             <img src={wordpress} alt="wprdpress" />
                                          </div>
                                          <div className="col-md-10">
                                             <div className="upgrade">
                                                <p className="m-0">
                                                   Fresh Install and configure wordpress at your server. If
                                                   you need help in installing and configuring wordpress,
                                                   Click here to find the tutorial.
                                                </p>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="upgrade-box-content mt-3 p-2 text-white  ">
                                       <div className="row">
                                          <div className="col-md-2 pr-0 text-center ">
                                             <img src={download} alt="download" />
                                          </div>
                                          <div className="col-md-10">
                                             <div className="upgrade">
                                                <p className="m-0">
                                                   Once wordpress is installed at your server. Download the
                                                   site content by clicking the "Download site button". This
                                                   will download a wpress file which you will be needing to
                                                   import in your wordpress site.
                                                </p>
                                                <div className="row  d-flex justify-content-center">
                                                   <div className="col-md-12">
                                                      <div className="upgrade-box-content text-center  text-white">
                                                         <div className="upgrade-para p-3">
                                                            <a
                                                               href="https://misctrainingvideos.s3.amazonaws.com/Agency+Website/AgencyWebsite_new.wpress"
                                                               target="_blank"
                                                            >
                                                               <button className="demoLink btn-block mt-0">
                                                                  Download the site
                                                               </button>
                                                            </a>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="upgrade-box-content mt-3 p-2 text-white  ">
                                       <div className="row">
                                          <div className="col-md-2 pr-0 text-center ">
                                             <img src={importIcon} alt="import" />
                                          </div>
                                          <div className="col-md-10">
                                             <div className="upgrade">
                                                <p className="m-0">
                                                   Import the xml file in to your wordpress site. If you need
                                                   help importing the xml file in wordpress, Click here to
                                                   find the tutorial.
                                                </p>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="upgrade-box-content mt-3 p-2 text-white  ">
                                       <div className="row">
                                          <div className="col-md-2 pr-0 text-center ">
                                             <img src={customize} alt="customize" />
                                          </div>
                                          <div className="col-md-10">
                                             <div className="upgrade">
                                                <p className="m-0">
                                                   Customize the site as according to your need. If you need
                                                   help customizing the site. Click here to find the tutorial
                                                </p>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="tabepaneIn-cont mt-4">
                              <h3>Tutorial</h3>
                              <div className="row">
                                 <div className="col-md-12">
                                    <div className="upload-box-content">
                                       <div className="row">
                                          <div className="col-md-12">
                                             <div className="tutorial-content">
                                                <div className="row">
                                                   <div className="col-md-2 text-center">
                                                      <img src={wordpress} alt="wordpress" />
                                                   </div>
                                                   <div className="col-md-10">
                                                      <div className="upgrade ml-lg-4">
                                                         <a className="btn-block"
                                                            href="https://misctrainingvideos.s3.amazonaws.com/Agency+Website/Install+Wordpress+in+cPanel.pdf"
                                                            target="_blank"
                                                         >
                                                            <button
                                                               title="Install WordPress From cPanel"
                                                               className="demoLink btn-block mt-0"
                                                            >
                                                               Install WordPress From cPanel
                                                            </button>
                                                         </a>
                                                      </div>
                                                   </div>
                                                </div>
                                                <div className="row mt-4">
                                                   <div className="col-md-2 text-center">
                                                      <img src={importIcon} />
                                                   </div>
                                                   <div className="col-md-10">
                                                      <div className="upgrade ml-lg-4">
                                                         <a
                                                            href="https://misctrainingvideos.s3.amazonaws.com/Agency+Website/Import+Agency+Data.pdf"
                                                            target="_blank"
                                                         >
                                                            <button className="demoLink btn-block mt-0">
                                                               Import Website
                                                            </button>
                                                         </a>
                                                      </div>
                                                   </div>
                                                </div>
                                                <div className="row mt-4">
                                                   <div className="col-md-2 text-center">
                                                      <img src={customize} />
                                                   </div>
                                                   <div className="col-md-10">
                                                      <div className="upgrade ml-lg-4">
                                                         <a
                                                            href="https://misctrainingvideos.s3.amazonaws.com/Agency+Website/Customize+Agency+Website.pdf"
                                                            target="_blank"
                                                         >
                                                            <button className="demoLink btn-block mt-0">
                                                               Customize website
                                                            </button>
                                                         </a>
                                                      </div>
                                                   </div>
                                                </div>
                                                <div className="row mt-4">
                                                   <div className="col-md-2 text-center">
                                                      <img src={download} />
                                                   </div>
                                                   <div className="col-md-10">
                                                      <div className="upgrade ml-lg-4">
                                                         <a
                                                            href="https://misctrainingvideos.s3.amazonaws.com/Agency+Website/Upload+Video+and+Usage+in+Agency+Website.pdf"
                                                            target="_blank"
                                                         >
                                                            <button
                                                               className="demoLink btn-block mt-0"
                                                               title="Upload Video and Usage in Website"
                                                            >
                                                               Upload Video and Usage in Website
                                                            </button>
                                                         </a>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="tabepaneIn-cont mt-4">
                              <h3>How to Install the Agency Website</h3>
                              <div className="row justify-content-center">
                                 <div className="col-md-6">
                                    <div className="card fb-card border-0">
                                       <div
                                          className="card-header text-center"
                                          style={{ background: "rgb(0, 123, 255)" }}
                                       >
                                          <h6 className="text-white Account-Management-headings">
                                             How to Install the Agency Website
                                          </h6>
                                       </div>
                                       <div className="card-body p-0">
                                          <img src={youtubeimg} alt="" className="img-fluid" />
                                          <span className="youtubeicon" onClick={(e) => addFrameUrl()} style={{ cursor: "pointer", color: '#007bff' }} data-toggle="modal" data-target="#MyModal" ><i class="fas fa-play-circle"></i></span>
                                          {/* 
                              <div className="vid-wrapper">
                                 <div className="plyr__video-embed" id="player">
                                    <iframe
                                       src="https://player.vimeo.com/video/514577280?"
                                       frameBorder={0}
                                       allow="autoplay"
                                       allowFullScreen
                                       width="100%"
                                       height="100%"
                                       />
                                 </div>
                              </div>
                              */}
                                       </div>
                                       <div >
                                       </div>
                                    </div>
                                    <div className={`modal VideoModal ${playing ? 'show-modal' : ''} `}>
                                       <div className="modal-dialog modal-dialog-centered" role="document">
                                          <div className="modal-content">
                                             {/* <div className="modal-header">
                        <h5 className="modal-title"></h5>
                        <button  type="button"  onClick={(e)=>closePop('')}  className="close" data-dismiss="modal"
                        aria-label="Close">
                        <span aria-hidden="true">
                        <i className="fas fa-times-circle" style={{color: "#ffffff"}} ></i></span>
                        </button>
                        </div> */}
                                             <div className="modal-body">
                                                <div onClick={(e) => closePop('')} data-dismiss="modal" className="vidClose"><img src={modalCloseIcon} /></div>
                                                <div className="embed-responsive embed-responsive-16by9">
                                                   <iframe id="frameSrc" className="embed-responsive-item" src={frameUrl} allowFullScreen=""></iframe>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="tab-pane fade" id="v-pills-social" role="tabpanel" aria-labelledby="v-pills-social-tab">
                     <div className="tabepaneIn">
                        <h2 className="tabTitle">Social Media Assets</h2>
                        <div className="tabepaneIn-main">
                           <div className="tabepaneIn-cont">
                              <h3>Social Media Assets</h3>
                              <div className="upgrade-para">
                                 <p className="text-center ">
                                    Download your plug &amp; play social media assets below
                                 </p>
                              </div>
                              <div className="row  d-flex justify-content-center  mt-3">
                                 <div className="col-xl-6">
                                    <div className="upgrade-box-content text-center  text-white">
                                       <div className="upgrade-para p-3">
                                          <a
                                             href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/Visual+Assets/24+Month+Social+Media+Calendar.xlsx"
                                             target="_blank"
                                          >
                                             <button className="demoLink btn-block mt-0">Social Media Calendar</button>
                                          </a>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-xl-6">
                                    <div className="upgrade-box-content text-center  text-white">
                                       <div className="upgrade-para p-3">
                                          <a
                                             href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/Visual+Assets/800+Motivational+Videos.zip"
                                             target="_blank"
                                          >
                                             <button className="demoLink btn-block mt-0">Social Media Captions</button>
                                          </a>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div className="row  d-flex justify-content-center  mt-3">
                                 <div className="col-xl-6">
                                    <div className="upgrade-box-content text-center  text-white">
                                       <div className="upgrade-para p-3">
                                          <a
                                             href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/Visual+Assets/6900%2B+Facts+Images.zip"
                                             target="_blank"
                                          >
                                             <button className="demoLink btn-block mt-0">
                                                Social Media Video Posts
                                             </button>
                                          </a>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-xl-6">
                                    <div className="upgrade-box-content text-center  text-white">
                                       <div className="upgrade-para p-3">
                                          <a
                                             href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/Visual+Assets/Captions.xlsx"
                                             target="_blank"
                                          >
                                             <button className="demoLink btn-block mt-0">
                                                Inspirational, Motivational Quotes Images
                                             </button>
                                          </a>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div className="row  d-flex justify-content-center  mt-3">
                                 <div className="col-xl-6">
                                    <div className="upgrade-box-content text-center  text-white">
                                       <div className="upgrade-para p-3">
                                          <a
                                             href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/Visual+Assets/Captions.xlsx"
                                             target="_blank"
                                          >
                                             {" "}
                                             <button className="demoLink btn-block mt-0">
                                                Social Media Image Posts
                                             </button>
                                          </a>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-xl-6">
                                    <div className="upgrade-box-content text-center  text-white">
                                       <div className="upgrade-para p-3">
                                          <a
                                             href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/Visual+Assets/Captions.xlsx"
                                             target="_blank"
                                          >
                                             {" "}
                                             <button className="demoLink btn-block mt-0">
                                                Library of Quotes for social Media{" "}
                                             </button>
                                          </a>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>


                  <div className="tab-pane fade master-video" id="v-pills-Training" role="tabpanel" aria-labelledby="v-pills-Training-tab">

                     <div className="tabepaneIn">
                        <h2 className="tabTitle">Master Class</h2>
                        <div className="tabepaneIn-main">

                           <div className="tabepaneIn-cont-alt">
                              <div className="upgrade-content gold-vip-wrapper">
                                 <div className="row me-1">
                                    <div className="col-xl-12 mb-4">
                                       <div className="upgrade-box-content text-center  text-white">
                                          <div className="upgrade-heading">
                                             <h6>The Power of Social Media Stories for Marketers</h6>
                                          </div>
                                          <div className="row">
                                             <div className="col-xl-6">
                                                <div className="upgrade-para p-3">
                                                   <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="https://reeelapps-app.s3.us-west-2.amazonaws.com/storyreel2/Master+Class/Training_Video/Introduction.mp4" allowfullscreen=""></iframe>
                                                </div>
                                             </div>
                                             <div className="col-xl-6">
                                                <div className="upgrade-para p-3">
                                                   <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="https://reeelapps-app.s3.us-west-2.amazonaws.com/storyreel2/Master+Class/Training_Video/Chapter+1.mp4" allowfullscreen=""></iframe>
                                                </div>
                                             </div>
                                             <div className="col-xl-6">
                                                <div className="upgrade-para p-3">
                                                   <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="https://reeelapps-app.s3.us-west-2.amazonaws.com/storyreel2/Master+Class/Training_Video/Chapter+2.mp4" allowfullscreen=""></iframe>
                                                </div>
                                             </div>
                                             <div className="col-xl-6">
                                                <div className="upgrade-para p-3">
                                                   <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="https://reeelapps-app.s3.us-west-2.amazonaws.com/storyreel2/Master+Class/Training_Video/Chapter+3.mp4" allowfullscreen=""></iframe>
                                                </div>
                                             </div>
                                             <div className="col-xl-6">
                                                <div className="upgrade-para p-3">
                                                   <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="https://reeelapps-app.s3.us-west-2.amazonaws.com/storyreel2/Master+Class/Training_Video/Chapter+4.mp4" allowfullscreen=""></iframe>
                                                </div>
                                             </div>
                                             <div className="col-xl-6">
                                                <div className="upgrade-para p-3">
                                                   <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="https://reeelapps-app.s3.us-west-2.amazonaws.com/storyreel2/Master+Class/Training_Video/Chapter+5.mp4" allowfullscreen=""></iframe>
                                                </div>
                                             </div>
                                             <div className="col-xl-6">
                                                <div className="upgrade-para p-3">
                                                   <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="https://reeelapps-app.s3.us-west-2.amazonaws.com/storyreel2/Master+Class/Training_Video/Chapter+6.mp4" allowfullscreen=""></iframe>
                                                </div>
                                             </div>
                                             <div className="col-xl-6">
                                                <div className="upgrade-para p-3">
                                                   <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="https://reeelapps-app.s3.us-west-2.amazonaws.com/storyreel2/Master+Class/Training_Video/Chapter+7.mp4" allowfullscreen=""></iframe>
                                                </div>
                                             </div>
                                             <div className="col-xl-6">
                                                <div className="upgrade-para p-3">
                                                   <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="https://reeelapps-app.s3.us-west-2.amazonaws.com/storyreel2/Master+Class/Training_Video/Chapter+8.mp4" allowfullscreen=""></iframe>
                                                </div>
                                             </div>
                                             <div className="col-xl-6">
                                                <div className="upgrade-para p-3">
                                                   <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="https://reeelapps-app.s3.us-west-2.amazonaws.com/storyreel2/Master+Class/Training_Video/Chapter+9.mp4" allowfullscreen=""></iframe>
                                                </div>
                                             </div>
                                             <div className="col-xl-6">
                                                <div className="upgrade-para p-3">
                                                   <iframe width="100%" height="300" id="frameSrc" class="embed-responsive-item" src="https://reeelapps-app.s3.us-west-2.amazonaws.com/storyreel2/Master+Class/Training_Video/Chapter+10.mp4" allowfullscreen=""></iframe>
                                                </div>
                                             </div>

                                          </div>
                                       </div>
                                    </div>


                                 </div>
                              </div>
                           </div>

                        </div>
                     </div>
                  </div>





                  <div className="tab-pane fade" id="v-pills-dfyarticles" role="tabpanel" aria-labelledby="v-pills-dfyarticles-tab">
                     <div className="tabepaneIn">
                        <h2 className="tabTitle">DFY Articles</h2>
                        <div className="tabepaneIn-main">
                           <div className="tabepaneIn-cont">
                              <h3>DFY Articles</h3>
                              <div className="row  d-flex justify-content-center  mt-3">
                                 <div className="col-xl-6">
                                    <div className="upgrade-box-content text-center  text-white">
                                       <div className="upgrade-para p-3">
                                          <a
                                             href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack1.zip"
                                             target="_blank"
                                          >
                                             {" "}
                                             <button className="demoLink btn-block mt-0">Artical Pack 1</button>
                                          </a>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-xl-6">
                                    <div className="upgrade-box-content text-center  text-white">
                                       <div className="upgrade-para p-3">
                                          <a
                                             href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack6.zip"
                                             target="_blank"
                                          >
                                             {" "}
                                             <button className="demoLink btn-block mt-0">Artical Pack 6</button>
                                          </a>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div className="row  d-flex justify-content-center  mt-3">
                                 <div className="col-xl-6">
                                    <div className="upgrade-box-content text-center  text-white">
                                       <div className="upgrade-para p-3">
                                          <a
                                             href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack2.zip"
                                             target="_blank"
                                          >
                                             <button className="demoLink btn-block mt-0">Artical Pack 2</button>
                                          </a>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-xl-6">
                                    <div className="upgrade-box-content text-center  text-white">
                                       <div className="upgrade-para p-3">
                                          <a
                                             href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack7.zip"
                                             target="_blank"
                                          >
                                             <button className="demoLink btn-block mt-0">Artical Pack 7</button>
                                          </a>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div className="row  d-flex justify-content-center  mt-3">
                                 <div className="col-xl-6">
                                    <div className="upgrade-box-content text-center  text-white">
                                       <div className="upgrade-para p-3">
                                          <a
                                             href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack2.zip"
                                             target="_blank"
                                          >
                                             <button className="demoLink btn-block mt-0">Artical Pack 3</button>
                                          </a>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-xl-6">
                                    <div className="upgrade-box-content text-center  text-white">
                                       <div className="upgrade-para p-3">
                                          <a
                                             href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack8.zip"
                                             target="_blank"
                                          >
                                             <button className="demoLink btn-block mt-0">Artical Pack 8 </button>
                                          </a>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div className="row  d-flex justify-content-center  mt-3">
                                 <div className="col-xl-6">
                                    <div className="upgrade-box-content text-center  text-white">
                                       <div className="upgrade-para p-3">
                                          <a
                                             href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack4.zip"
                                             target="_blank"
                                          >
                                             {" "}
                                             <button className="demoLink btn-block mt-0">Artical Pack 4</button>
                                          </a>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-xl-6">
                                    <div className="upgrade-box-content text-center  text-white">
                                       <div className="upgrade-para p-3">
                                          <a
                                             href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack8.zip"
                                             target="_blank"
                                          >
                                             {" "}
                                             <button className="demoLink btn-block mt-0">Artical Pack 8 </button>
                                          </a>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div className="row  d-flex justify-content-center  mt-3">
                                 <div className="col-xl-6">
                                    <div className="upgrade-box-content text-center  text-white">
                                       <div className="upgrade-para p-3">
                                          <a
                                             href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack5.zip"
                                             target="_blank"
                                          >
                                             <button className="demoLink btn-block mt-0">Artical Pack 5</button>
                                          </a>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-xl-6">
                                    <div className="upgrade-box-content text-center  text-white">
                                       <div className="upgrade-para p-3">
                                          <a
                                             href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack10.zip"
                                             target="_blank"
                                          >
                                             <button className="demoLink btn-block mt-0">Artical Pack 10 </button>
                                          </a>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div
                     className="tab-pane fade"
                     id="v-pills-affiliate"
                     role="tabpanel"
                     aria-labelledby="v-pills-affiliate-tab"
                  >
                     <div className="tabepaneIn">
                        <h2 className="tabTitle">Afftrack Affiliate Approval</h2>
                        <div className="tabepaneIn-main">
                           <div className="tabepaneIn-cont">
                              <div>
                                 <div className="">
                                    <div className="">
                                       <div className="">
                                          <div className="">
                                             <div className="">
                                                <h3>Afftrack Affiliate</h3>
                                             </div>
                                             <div className="">
                                                <p className="text-center ">
                                                   If you are not approved within 24 hours, please open a ticket at
                                                   support@vineasx.com
                                                   with your username and email on Paykickstart to get
                                                   approved. We can not approve you if you have a refund rate over 10%
                                                </p>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="row  d-flex text-center  mt-3">
                                       <div className="col-md-6">
                                          <div className="upgrade-box-content text-center  text-white">
                                             <div className="upgrade-para p-3">
                                                <a
                                                   href="https://paykstrt.com/request/10875"
                                                   target="_blank"
                                                >
                                                   <button className="btn-change7">
                                                      Link 1
                                                   </button>
                                                </a>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-md-6">
                                          <div className="upgrade-box-content text-center  text-white">
                                             <div className="upgrade-para p-3">
                                                <a
                                                   href="https://paykstrt.com/request/10909"
                                                   target="_blank"
                                                >
                                                   <button className="btn-change7">
                                                      Link 2
                                                   </button>
                                                </a>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="row  d-flex text-center  mt-3">
                                       <div className="col-md-6">
                                          <div className="upgrade-box-content text-center  text-white">
                                             <div className="upgrade-para p-3">
                                                <a
                                                   href="https://paykstrt.com/request/11286"
                                                   target="_blank"
                                                >
                                                   <button className="btn-change7">
                                                      Link 3
                                                   </button>
                                                </a>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-md-6">
                                          <div className="upgrade-box-content text-center  text-white">
                                             <div className="upgrade-para p-3">
                                                <a
                                                   href="https://paykstrt.com/request/11303"
                                                   target="_blank"
                                                >
                                                   <button className="btn-change7">
                                                      Link 4
                                                   </button>
                                                </a>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="row  d-flex text-center  mt-3">
                                       <div className="col-md-6">
                                          <div className="upgrade-box-content text-center  text-white">
                                             <div className="upgrade-para p-3">
                                                <a
                                                   href="https://paykstrt.com/request/11304"
                                                   target="_blank"
                                                >
                                                   <button className="btn-change7">
                                                      Link 5
                                                   </button>
                                                </a>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-md-6">
                                          <div className="upgrade-box-content text-center  text-white">
                                             <div className="upgrade-para p-3">
                                                <a
                                                   href="https://paykstrt.com/request/11676"
                                                   target="_blank"
                                                >
                                                   <button className="btn-change7">
                                                      Link 6
                                                   </button>
                                                </a>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="row  d-flex text-center  mt-3">
                                       <div className="col-md-6">
                                          <div className="upgrade-box-content text-center  text-white">
                                             <div className="upgrade-para p-3">
                                                <a
                                                   href="https://paykstrt.com/request/12253"
                                                   target="_blank"
                                                >
                                                   <button className="btn-change7">
                                                      Link 7
                                                   </button>
                                                </a>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-md-6">
                                          <div className="upgrade-box-content text-center  text-white">
                                             <div className="upgrade-para p-3">
                                                <a
                                                   href="https://paykstrt.com/request/13339"
                                                   target="_blank"
                                                >
                                                   <button className="btn-change7">
                                                      Link 8
                                                   </button>
                                                </a>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="row  d-flex text-center  mt-3">
                                       <div className="col-md-6">
                                          <div className="upgrade-box-content text-center  text-white">
                                             <div className="upgrade-para p-3">
                                                <a
                                                   href="https://paykstrt.com/request/13399"
                                                   target="_blank"
                                                >
                                                   <button className="btn-change7">
                                                      Link 9
                                                   </button>
                                                </a>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-md-6">
                                          <div className="upgrade-box-content text-center  text-white">
                                             <div className="upgrade-para p-3">
                                                <a
                                                   href="https://paykstrt.com/request/14240"
                                                   target="_blank"
                                                >
                                                   <button className="btn-change7">
                                                      Link 10
                                                   </button>
                                                </a>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="row  d-flex text-center  mt-3">
                                       <div className="col-md-6">
                                          <div className="upgrade-box-content text-center  text-white">
                                             <div className="upgrade-para p-3">
                                                <a
                                                   href="https://paykstrt.com/request/14352"
                                                   target="_blank"
                                                >
                                                   <button className="btn-change7">
                                                      Link 11
                                                   </button>
                                                </a>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-md-6">
                                          <div className="upgrade-box-content text-center  text-white">
                                             <div className="upgrade-para p-3">
                                                <a
                                                   href="https://paykstrt.com/request/15185"
                                                   target="_blank"
                                                >
                                                   <button className="btn-change7">
                                                      Link 12
                                                   </button>
                                                </a>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="row  d-flex text-center  mt-3">
                                       <div className="col-md-6">
                                          <div className="upgrade-box-content text-center  text-white">
                                             <div className="upgrade-para p-3">
                                                <a
                                                   href="https://paykstrt.com/request/15252"
                                                   target="_blank"
                                                >
                                                   <button className="btn-change7">
                                                      Link 13
                                                   </button>
                                                </a>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-md-6">
                                          <div className="upgrade-box-content text-center  text-white">
                                             <div className="upgrade-para p-3">
                                                <a
                                                   href="https://paykstrt.com/request/15582"
                                                   target="_blank"
                                                >
                                                   <button className="btn-change7">
                                                      Link 14
                                                   </button>
                                                </a>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="row  d-flex text-center  mt-3">
                                       <div className="col-md-6">
                                          <div className="upgrade-box-content text-center  text-white">
                                             <div className="upgrade-para p-3">
                                                <a
                                                   href="https://paykstrt.com/request/15646"
                                                   target="_blank"
                                                >
                                                   <button className="btn-change7">
                                                      Link 15
                                                   </button>
                                                </a>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-md-6">
                                          <div className="upgrade-box-content text-center  text-white">
                                             <div className="upgrade-para p-3">
                                                <a
                                                   href="https://paykstrt.com/request/16233"
                                                   target="_blank"
                                                >
                                                   <button className="btn-change7">
                                                      Link 16
                                                   </button>
                                                </a>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="row  d-flex text-center  mt-3">
                                       <div className="col-md-6">
                                          <div className="upgrade-box-content text-center  text-white">
                                             <div className="upgrade-para p-3">
                                                <a
                                                   href="https://paykstrt.com/request/16368"
                                                   target="_blank"
                                                >
                                                   <button className="btn-change7">
                                                      Link 17
                                                   </button>
                                                </a>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-md-6">
                                          <div className="upgrade-box-content text-center  text-white">
                                             <div className="upgrade-para p-3">
                                                <a
                                                   href="https://paykstrt.com/request/17055"
                                                   target="_blank"
                                                >
                                                   <button className="btn-change7">
                                                      Link 18
                                                   </button>
                                                </a>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="row  d-flex text-center  mt-3">
                                       <div className="col-md-6">
                                          <div className="upgrade-box-content text-center  text-white">
                                             <div className="upgrade-para p-3">
                                                <a
                                                   href="https://paykstrt.com/request/17297"
                                                   target="_blank"
                                                >
                                                   <button className="btn-change7">
                                                      Link 19
                                                   </button>
                                                </a>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-md-6">
                                          <div className="upgrade-box-content text-center  text-white">
                                             <div className="upgrade-para p-3">
                                                <a
                                                   href="https://paykstrt.com/request/17810"
                                                   target="_blank"
                                                >
                                                   <button className="btn-change7">
                                                      Link 20
                                                   </button>
                                                </a>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="row  d-flex text-center  mt-3">
                                       <div className="col-md-6">
                                          <div className="upgrade-box-content text-center  text-white">
                                             <div className="upgrade-para p-3">
                                                <a
                                                   href="https://paykstrt.com/request/18279"
                                                   target="_blank"
                                                >
                                                   <button className="btn-change7">
                                                      Link 21
                                                   </button>
                                                </a>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-md-6">
                                          <div className="upgrade-box-content text-center  text-white">
                                             <div className="upgrade-para p-3">
                                                <a
                                                   href="https://paykstrt.com/request/18673"
                                                   target="_blank"
                                                >
                                                   <button className="btn-change7">
                                                      Link 22
                                                   </button>
                                                </a>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="row  d-flex text-center  mt-3">
                                       <div className="col-md-6">
                                          <div className="upgrade-box-content text-center  text-white">
                                             <div className="upgrade-para p-3">
                                                <a
                                                   href="https://paykstrt.com/request/19039"
                                                   target="_blank"
                                                >
                                                   <button className="btn-change7">
                                                      Link 23
                                                   </button>
                                                </a>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-md-6">
                                          <div className="upgrade-box-content text-center  text-white">
                                             <div className="upgrade-para p-3">
                                                <a
                                                   href="https://paykstrt.com/request/19262"
                                                   target="_blank"
                                                >
                                                   <button className="btn-change7">
                                                      Link 24
                                                   </button>
                                                </a>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="row  d-flex text-center  mt-3">
                                       <div className="col-md-6">
                                          <div className="upgrade-box-content text-center  text-white">
                                             <div className="upgrade-para p-3">
                                                <a
                                                   href="https://paykstrt.com/request/19269"
                                                   target="_blank"
                                                >
                                                   <button className="btn-change7">
                                                      Link 25
                                                   </button>
                                                </a>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-md-6">
                                          <div className="upgrade-box-content text-center  text-white">
                                             <div className="upgrade-para p-3">
                                                <a
                                                   href="https://paykstrt.com/request/19415"
                                                   target="_blank"
                                                >
                                                   <button className="btn-change7">
                                                      Link 26
                                                   </button>
                                                </a>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div
                     className="tab-pane fade"
                     id="v-pills-youtube"
                     role="tabpanel"
                     aria-labelledby="v-pills-youtube-tab"
                  >
                     <div className="tabepaneIn-main">
                        <div className="tabepaneIn-cont">
                           <h3>YouTube Keyword Research</h3>
                           <YoutubeKeySearch />
                        </div>
                     </div>
                     {/* 
      <h5 className="text-white mb-3">YouTube Keyword Research</h5>
      <YoutubeKeySearch />
      */}
                     {/* <iframe
      src="https://app.rankreel.io/?redirect=%2Fkeyword-suggestion"
      style={{ width: "100%", height: "90vh" }}
      /> */}
                     {/* 
      <div className="upgrade-content">
         <div className="row">
            <div className="col-md-12">
               <iframe />
            </div>
         </div>
      </div>
      */}
                  </div>
                  <div
                     className="tab-pane fade"
                     id="v-pills-intro-outro"
                     role="tabpanel"
                     aria-labelledby="v-pills-intro-outro-tab"
                  >
                     <div className="tabepaneIn">
                        <div className="tabepaneIn-main">
                           <div className="tabepaneIn-cont">
                              <h3>Intro/Outro Creater</h3>
                              <div className="upgrade-contentn">
                                 <div className="row">
                                    <div className="col-md-12 mx-auto">
                                       <div className="">
                                          <p className="text-center ">
                                             Please raise a support ticket with your ReviewReel purchase
                                             transaction ID and a note about how you would be using the Intro/Outro Creater for
                                             your business.
                                          </p>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="row  d-flex justify-content-center  mt-5">
                                    <div className="col-md-12">
                                       <div className="upgrade-box-content text-center  text-white">
                                          <div className="upgrade-para p-3">
                                             <a href="https://support.vineasx.com/" target="_blank">
                                                <button className="btn-change7">Raise a Ticket</button>
                                             </a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="tab-pane fade" id="v-pills-Social-Traffic-Generator" role="tabpanel" aria-labelledby="v-pills-Social-Traffic-Generator-tab">
                     <div className="tabepaneIn">
                        <div className="tabepaneIn-main">
                           <div className="tabepaneIn-cont">
                              <h3>Social Traffic Generator</h3>
                              <p className="text-center ">
                                 Download Social Traffic Generator
                              </p>
                              <div className="row  d-flex justify-content-center  mt-5">
                                 <div className="upgrade-box-content text-center  text-white">
                                    <div className="upgrade-para p-3">
                                       <a
                                          href="https://s3-us-west-2.amazonaws.com/adsviser3/library/adpack1.zip"
                                          target="_blank"
                                       >
                                          <button className="btn-change7">Download </button>
                                       </a>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="tab-pane fade" id="v-pills-business" role="tabpanel" aria-labelledby="v-pills-business-tab">
                     <div className="tabepaneIn">
                        <h2 className="tabTitle">Business Finder</h2>
                        <div className="tabepaneIn-main">
                           <div className="tabepaneIn-cont">
                              <h3>Business Finder</h3>
                              <iframe
                                 src="https://resources.vega6.info/business-finder?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpYXQiOjE2MDYxMjUyMjcsImp0aSI6IldZY3gwQW42cnFHcHdhRUluYlNWTWwiLCJpc3MiOiJodHRwczpcL1wvcmVzb3VyY2VzLnZlZ2E2LmluZm9cLyIsIm5iZiI6MTYwNjEyNTIzNywiZGF0YSI6eyJ1c2VyX2lkIjoiMSIsImFwcF91cmwiOiJodHRwczpcL1wvdjJzZXJ2ZXIudmlkZW9yZWVsLmlvXC8ifX0.fN5JpYG7hFfXgprbTLyGbtbBE-T4-8SG25xtn4ckOkGmwevJEANb2UjngHIaUiAVNNmzVIeLotsVRZb9aB9g_Q"
                                 style={{ width: "100%", height: "100vh" }}
                              />
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   )
}
export default UpgradesContent;