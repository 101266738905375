import React, { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { addLayerLink } from '../../../actions/storyAction'

const LayerLink = ({ selectedSlide, selectedSlideIndex }) => {
    const dispatch = useDispatch()
    const selectedLayer = selectedSlide.layers.find(({ isSelected }) => isSelected === true)
    const selectedLayerIndex = selectedSlide.layers.findIndex(({ isSelected }) => isSelected === true)

    const [link, setLink] = useState({
        toggle: false,
        url: ""
    })

    const handleLink = (e) => {
        setLink({
            ...link,
            toggle: e.target.checked
        })
    }

    const updateLink = (e) => {
        setLink({
            ...link,
            url: e.target.value
        })
        dispatch(addLayerLink(e.target.value, selectedSlideIndex, selectedLayerIndex))
    }
    useEffect(() => {
        if (selectedLayer) {
            if (selectedLayer.url) {
                setLink({
                    ...link,
                    url: selectedLayer.url
                })
            } else {
                setLink({
                    ...link,
                    url: ""
                })
            }
        }
    }, [selectedLayer])
    return (
        <div className="borderselect pt-3">
            <div className="titleSelect mb-3">
                <h2 className="tabTitleN">Add Link</h2>
                <label className="switch">
                    <input
                        type="checkbox"
                        checked={link.toggle}
                        onChange={(e) => handleLink(e)}
                    />
                    <span className="slider round"></span>
                </label>
            </div>
            {link.toggle ?
                <div className="select-your-color pt-0">
                    <div className="select-your-color-opt mt-0">
                        <input
                            className="inpField"
                            type="text"
                            placeholder="Add Link Here"
                            value={link.url}
                            onChange={(e) => updateLink(e)}
                        />
                    </div>
                </div>
                : ""}
        </div>
    )
}

export default LayerLink