import React, { useState, useEffect } from 'react'
import { AiOutlineCloudUpload } from "react-icons/ai";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import swal from 'sweetalert';
import { publishTitle, publishLanguage, uploadPublishMedia, publishName } from '../../actions/publishAction';
import { googleLanguages, onFileUpload } from '../../actions/storyAction';

const PublisherDetails = () => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    let allowedExt = ['image/png', 'image/jpg', 'image/jpeg'];
    const [language, setLanguage] = useState([])
    const [logoLoader, setLogoLoader] = useState(false)
    const [logoPercent, setLogoPercent] = useState(0)
    const [favLoader, setFavLoader] = useState(false)
    const [favPercent, setFavPercent] = useState(0)
    const [imageData, setImageData] = useState({
        logo: false,
        favIcon: false
    })
    const [state, setState] = useState({
        storyTitle: "",
        storyLanguage: "",
        publisherName: "",
    })
    const handleChange = (e) => {
        const { name, value } = e.target
        setState({
            ...state,
            [name]: value
        })
        if (name === "storyTitle") {
            dispatch(publishTitle(value))
        }
        if (name === "storyLanguage") {
            dispatch(publishLanguage(value))
        }
        if (name === "publisherName") {
            dispatch(publishName(value))
        }

    }

    const onInputFile = (e, type) => {

        let allowedSize = 5000000;
        if (allowedExt.includes(e.target.files[0].type)) {
            if (e.target.files[0].size < allowedSize) {
                const formData = new FormData()
                formData.append('file', e.target.files[0])
                formData.append('user_id', auth.user.id)
                if (type === "logo") {
                    setLogoLoader(true)
                    formData.append('upload_type', type)
                    dispatch(uploadPublishMedia(formData, setLogoLoader, setLogoPercent, imageData, setImageData, type))
                }
                if (type === "favicon") {
                    setFavLoader(true)
                    formData.append('upload_type', "watermark")
                    dispatch(uploadPublishMedia(formData, setFavLoader, setFavPercent, imageData, setImageData, type))
                }


            } else {
                swal("Oops!", "Max allowed size is 5MB");
            }
        } else {
            swal("Oops!", "You have Selected Invalid File Type");
        }
    }


    useEffect(() => {
        dispatch(googleLanguages(setLanguage))
    }, [])

    return (
        <div className="formWrap">
            <div className="profileBlock">
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group form-group-alt mt-0">
                            <label>Story Title</label>
                            <input
                                className="form-control"
                                placeholder="New Story"
                                type="text"
                                value={state.storyTitle}
                                name="storyTitle"
                                onChange={(e) => handleChange(e)}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group form-group-alt mt-0">
                            <label>Story Language</label>
                            <select
                                className="form-control"
                                value={state.storyLanguage}
                                name="storyLanguage"
                                onChange={(e) => handleChange(e)}
                            >
                                <option value={""}>Select Language</option>
                                {
                                    language.length > 0 ?
                                        language.map((curElem, index) => {
                                            
                                            return (
                                                <option
                                                    value={curElem.code}
                                                    key={index}
                                                >
                                                    {curElem.name}
                                                </option>
                                            )
                                        }) : ""
                                }
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div className="profileBlock">
                <h2 class="text-center text-white">Publisher Details</h2>
                <div className="form-group form-group-alt">
                    <label>Name</label>
                    <input
                        className="form-control"
                        placeholder="Publisher Name"
                        type="text"
                        value={state.publisherName}
                        name="publisherName"
                        onChange={(e) => handleChange(e)}
                    />
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="compactUpload mt-4">
                            <div >
                                {logoLoader ?
                                    <div className='loaderStat' style={{ position: "relative" }}>
                                        <i className="fa fa-spinner fa-spin" style={{ color: "#fbd90e", fontSize: "45px" }} />
                                        <span style={{ color: "#ffffff", fontSize: "12px" }} >{logoPercent}%</span>
                                    </div>
                                    :
                                    <>
                                        <div className="text-white">
                                            <AiOutlineCloudUpload />
                                            {imageData.logo.path ?
                                            <div className='upIMg'><img src={imageData.logo.path} alt="" /></div>: ""}
                                        </div>
                                        <p>{imageData.logo.name ? imageData.logo.name : "Upload Logo"}</p>
                                        <input
                                            type="file"
                                            onChange={(e) => onInputFile(e, "logo")}
                                        />

                                    </>}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="compactUpload mt-4">
                            <div>
                                {favLoader ?
                                    <div className='loaderStat' style={{ position: "relative" }}>
                                        <i className="fa fa-spinner fa-spin" style={{ color: "#fbd90e", fontSize: "45px" }} />
                                        <span style={{ color: "#ffffff", fontSize: "12px" }} >{favPercent}%</span>
                                    </div>
                                    :
                                    <>
                                        <div className="text-white">
                                            <AiOutlineCloudUpload />
                                            {imageData.favIcon.path ?
                                            <div className='upIMg'><img src={imageData.favIcon.path} alt="" /></div>: ""}
                                        </div>
                                        <p>{imageData.favIcon.name ? imageData.favIcon.name : "Upload Favicon"}</p>
                                        <input
                                            type="file"
                                            onChange={(e) => onInputFile(e, "favicon")}
                                        />
                                    </>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default PublisherDetails