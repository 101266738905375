import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {changeEmail} from "../../actions/authAction";
import Alert from "../Alert";

const PrivacyEmail = () => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);

    const [loader, setLoader] = useState(false);
    const [userDetails, setUserDetails] = useState({
        email: '',
    })

    const onInputChange = (e) => {
        setUserDetails({...userDetails, email: e.target.value});
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        setLoader(true);

        dispatch(changeEmail(userDetails, setLoader));
    }

    return(
        <>
            <Alert/>


        <div className="profileBlock-wrap">
            <h2 className="infoTitle">Change Email</h2>
            <div className="profileBlock mt-0">
            <form className="" method="post" onSubmit={(e)=>onFormSubmit(e)}>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group form-group-alt">
                            <label>Current Email Address</label>
                            <input type="email" className="form-control"
                                   placeholder="Enter Current Email Address"
                                   value={auth.user?.email} readOnly
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group form-group-alt">
                            <label>New Email Address</label>
                            <input type="email" className="form-control"
                                   placeholder="Enter Email Address" required name="email"
                                   onChange={(e)=>onInputChange(e)}
                            />
                        </div>
                    </div>
                </div>
 
                <button type="submit" className="fbtn btn-block demoLink" disabled={loader}>{ loader ? <i className="fa fa-spinner fa-spin mr-2"/>  : '' } Update</button>
                        
            </form>
            </div>
        </div>

        </>
    )
}

export default PrivacyEmail;