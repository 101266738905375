import React, { useState, useCallback } from 'react'
import Tab from 'react-bootstrap/Tab';
import { setAlert } from '../../../../actions/alert';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import HoverVideoPlayer from "react-hover-video-player";
import { commonAxios } from '../../../../global/CommonAxios';
import { addLayerMedia, cropVideo, updateBgMedia, updateLayerMedia } from '../../../../actions/storyAction';
import Cropper from 'react-easy-crop';

const Video = ({ selectedSlide, selectedSlideIndex, type, selectedLayer, selectedLayerIndex }) => {
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth)
    const [loader, setLoader] = useState(false);
    const [videoData, setVideoData] = useState([]);
    const [cropModel, setCropModel] = useState(false);
    const [currentImage, setCurrentImage] = useState(false);
    const [outputVideo, setOutputVideo] = useState(false);
    const [outputThumb, setOutputThumb] = useState(false);
    const [cropLoader, setCropLoader] = useState(false);

    /*Crop image or video*/
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [searchVideo, setSearchVideo] = useState({
        keyword: 'nature',
        search_type: 'video',
        page: 1,
        selectedVidIndex: -1
    });

    const fetchVideo = (page = 1) => {
        setLoader(true);
        let data = {
            keyword: searchVideo.keyword,
            type: searchVideo.search_type,
            page_number: page
        }
        commonAxios("load-library-images", data, dispatch, auth.token)
            .then((res) => {
                if (res.status) {
                    let arr = [...videoData];
                    setVideoData(arr.concat(res.data));
                } else {
                    dispatch(setAlert(res.msg, "danger"))
                }
                setLoader(false);
                setSearchVideo({ ...searchVideo, page: page });
            }).catch((err) => {
                setLoader(false);
                console.log(err)
            })
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        fetchVideo();
    }


    const onInputChange = (e) => {
        setSearchVideo({ ...searchVideo, keyword: e.target.value })
        setVideoData([]);
    }

    const handleSelectVideo = (curElem, index) => {
        setSearchVideo({
            ...searchVideo,
            selectedVidIndex: index
        })
        let objData = {
            url: curElem.url,
            mediaType: "video"
        }
        if (type === "background") {
            dispatch(updateBgMedia(objData, selectedSlideIndex))
        } else {
            if (selectedSlide.layers.length > 0) {
                if (selectedLayerIndex !== -1 && selectedLayer.type !== "text") {
                    dispatch(updateLayerMedia(objData, selectedSlideIndex, selectedLayerIndex))
                } else {
                    dispatch(addLayerMedia(objData, selectedSlideIndex, selectedLayerIndex))
                }
            } else {
                dispatch(addLayerMedia(objData, selectedSlideIndex, selectedLayerIndex))
            }
        }

    }

    const selectCropImage = (video) => {
        let objData = {
            url: video,
            mediaType: "video"
        }
        if (type === "background") {
            dispatch(updateBgMedia(objData, selectedSlideIndex))
        } else {
            if (selectedSlide.layers.length > 0) {
                if (selectedLayerIndex !== -1 && selectedLayer.type !== "text") {
                    dispatch(updateLayerMedia(objData, selectedSlideIndex, selectedLayerIndex))
                } else {
                    dispatch(addLayerMedia(objData, selectedSlideIndex, selectedLayerIndex))
                }
            } else {
                dispatch(addLayerMedia(objData, selectedSlideIndex, selectedLayerIndex))
            }
        }
        setCropModel(false);
        setOutputVideo(false);
    }

    const selectCrop = (e, url) => {
        e.stopPropagation()
        setCropModel(true);
        setCurrentImage(url);
        setOutputVideo(false)
    }

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)

    }, [])

    const cropVideoFile = () => {
        setCropLoader(true);
        let formData = new FormData();
        formData.append('width', croppedAreaPixels.width);
        formData.append('height', croppedAreaPixels.height);
        formData.append('videourl', currentImage);
        formData.append('postionx', croppedAreaPixels.x);
        formData.append('postiony', croppedAreaPixels.y);
        formData.append('user_id', auth.user.id);

        dispatch(cropVideo(formData, setOutputVideo, setOutputThumb, setCropLoader))
    }


    return (
        <Tab.Pane eventKey="viDeo">
            <div className="videoOpt">
                <form
                    className="imageSearch"
                    style={{ position: "relative" }}
                    onSubmit={(e) => onFormSubmit(e)}
                >
                    <input
                        type="text"
                        placeholder="Search Video"
                        name="video"
                        onInput={(e) => onInputChange(e)} />
                    <span
                        className="search-btn"

                    ><i class="fa fa-search" aria-hidden="true" onClick={() => { fetchVideo() }}></i></span>
                </form>

                <div className="scrollDiv scrollDiv-3" id={`scrollableVideo${type}`}>
                    <div className="videoSelect">
                        <ul>
                            <InfiniteScroll
                                dataLength={videoData.length} //This is important field to render the next data
                                next={() => fetchVideo(searchVideo.page + 1)}
                                hasMore={true}
                                scrollableTarget={`scrollableVideo${type}`}
                            >
                                {videoData.length > 0 ?
                                    videoData.map((curElem, index) => {
                                        return (
                                            <>
                                                {curElem ?
                                                    <li
                                                        key={index}
                                                        onClick={() => handleSelectVideo(curElem, index)}
                                                        style={{ position: "relative" }}
                                                        className="cursor-pointer"
                                                    >
                                                        <HoverVideoPlayer
                                                            key={index}
                                                            className="videoSelect-single"
                                                            style={{ border: '1px solid gray', height: '150px', overflow: 'hidden' }}
                                                            videoSrc={curElem.url}
                                                            pausedOverlay={
                                                                <img src={curElem.thumbnail} alt={`${fetchVideo.keyword + ' ' + index}`} width="100%" height="100%" style={{ objectFit: "cover" }} />
                                                            }
                                                            loadingOverlay={
                                                                <div><i className="fa fa-spinner fa-spin hover-loader-center" /></div>
                                                            }
                                                        />
                                                        {searchVideo.selectedVidIndex === index ?
                                                            <div className="slide-active">
                                                                <i className="fa fa-check" aria-hidden="true"></i>
                                                            </div>
                                                            : ''
                                                        }
                                                        <div className="crop-icon" onClick={(e) => selectCrop(e, curElem.url)}>
                                                            <i className="fa fa-crop"></i>
                                                        </div>

                                                    </li> : ''
                                                }
                                            </>
                                        )
                                    }) : ''
                                }
                                {
                                    loader ?
                                        <div className="col-md-12">
                                            <h4 className='text-center'><i className="fa fa-spinner fa-spin " /></h4>
                                        </div>
                                        : ''
                                }
                            </InfiniteScroll>

                        </ul>
                    </div>
                </div>
                <div className={`modal ${cropModel ? 'show-modal' : ''} `} style={{ height: "auto" }}>
                    <div className="modal-dialog" role="document">
                        <div className="modal-content crop-video">
                            <div className="modal-header crop-header">
                                <h5 className="modal-title text-center">Crop</h5>
                                <button onClick={(e) => setCropModel(false)} type="button" className="close img-close " data-dismiss="modal"
                                    aria-label="Close">
                                    <span aria-hidden="true"><i className="fas fa-times-circle" style={{ color: "#ffffff" }} ></i></span>
                                </button>
                            </div>
                            <div className="modal-body" style={{ position: "relative", height: "54vh" }}>

                                <div className="tab-content text-white" id="pills-tabContent">
                                    <div className="tab-pane editor-panel fade show active" id="pills-crop"
                                        role="tabpanel" aria-labelledby="pills-story-tab">
                                        <Cropper
                                            video={currentImage}
                                            crop={crop}
                                            zoom={zoom}
                                            aspect={9 / 16}
                                            onCropChange={setCrop}
                                            onZoomChange={setZoom}
                                            onCropComplete={onCropComplete}
                                        />
                                        {
                                            croppedAreaPixels === null ?
                                                <div className="crop-icon-center">
                                                    <i className="fa fa-spinner fa-spin mr-2" style={{ color: "#F68802", fontSize: '25px' }} />
                                                </div> : ''
                                        }
                                    </div>
                                    <div className="tab-pane editor-panel fade" id="pills-trim"
                                        role="tabpanel" aria-labelledby="pills-story-tab">
                                        <h4>Trim Video</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer d-flex justify-content-between">
                                <button className="btn-custom mr-1" type="button" onClick={() => cropVideoFile()}>
                                    {cropLoader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''}
                                    {cropLoader ? 'Cropping' : 'Crop'}
                                </button>
                                <button
                                    className={`${outputVideo === false ? 'btn-custom-disable' : 'btn-custom'}`}
                                    type="button"
                                    disabled={outputVideo === false ? true : false}
                                    onClick={() => selectCropImage(outputVideo)} >Use</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </Tab.Pane>
    )
}

export default Video