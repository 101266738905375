import React, { useState } from 'react'
import { useEffect } from 'react';
import Tab from 'react-bootstrap/Tab';
import { AiOutlineCloudUpload, AiOutlineDelete } from "react-icons/ai";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import swal from 'sweetalert';
import { setAlert } from '../../../../actions/alert';
import { addLayerMedia, deleteUpload, generateFile, getUploadData, onDeleteStory, onFileUpload, updateBgMedia, updateLayerMedia } from '../../../../actions/storyAction';
import HoverVideoPlayer from "react-hover-video-player";
import SweetAlert from 'react-bootstrap-sweetalert';
import Cropper from 'react-easy-crop';
import { useCallback } from 'react';
import getCroppedImg from "./CropImage";


const Uploads = ({ selectedSlide, selectedSlideIndex, type, selectedLayer, selectedLayerIndex }) => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    let allowedExt = ['image/png', 'image/jpg', 'image/jpeg', 'video/mp4'];
    const [percent, setPercent] = useState(0)
    const [loader, setLoader] = useState(false)
    const [image, setImage] = useState([])
    const [video, setVideo] = useState([])

    const [cropModel, setCropModel] = useState(false);
    const [currentImage, setCurrentImage] = useState(false);
    const [outputImage, setOutputImage] = useState(false);
    const [cropLoader, setCropLoader] = useState(false);
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [rotation, setRotation] = useState(0)
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [croppedImage, setCroppedImage] = useState(null)


    const [deleteFile, setDeleteFile] = useState({
        showDelete: false,
        button: "Delete",
        fileId: -1,
        type: false
    })
    const [state, setState] = useState({
        selectImageIndex: -1,
        selectVideoIndex: -1
    })
    const handleUpload = (e) => {
        let type = "video"
        let allowedSize = 20000000;
        if (allowedExt.includes(e.target.files[0].type)) {
            if (e.target.files[0].type !== "video/mp4") {
                allowedSize = 5000000
                type = "images"
            }
            if (e.target.files[0].size < allowedSize) {
                const formData = new FormData()
                formData.append('upload_type', type)
                formData.append('file', e.target.files[0])
                formData.append('user_id', auth.user.id)
                dispatch(onFileUpload(formData, auth.token, fetchMedia, setLoader, setPercent, type, setImage, setVideo))
            } else {
                swal("Oops!", "Max allowed size for image 5MB for video 20MB");
            }
        } else {
            swal("Oops!", "You have Selected Invalid File Type");
        }
    }


    const selectCropImage = (image) => {
        let objData = {
            url: image,
            mediaType: "image"
        }
        if (type === "background") {
            dispatch(updateBgMedia(objData, selectedSlideIndex))
        } else {
            if (selectedSlide.layers.length > 0) {
                if (selectedLayerIndex !== -1 && selectedLayer.type !== "text") {
                    dispatch(updateLayerMedia(objData, selectedSlideIndex, selectedLayerIndex))
                } else {
                    dispatch(addLayerMedia(objData, selectedSlideIndex, selectedLayerIndex))
                }
            }
            else {
                dispatch(addLayerMedia(objData, selectedSlideIndex, selectedLayerIndex))
            }
        }

        setCropModel(false);
        setOutputImage(false);
    }


    const showCroppedImage = useCallback(async () => {
        setCropLoader(true);
        try {
            const croppedImage = await getCroppedImg(currentImage, croppedAreaPixels, rotation)
            downloadFiles(croppedImage);
            setCroppedImage(croppedImage)
        } catch (e) {
            console.error(e)
        }
    }, [croppedAreaPixels, rotation])

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const downloadFiles = (imageData) => {
        let ext = currentImage.match(/\.([^\./\?]+)($|\?)/)[1];
        setCropLoader(true);
        let data = JSON.stringify({ data: imageData, format: ext })
        dispatch(generateFile(data, setOutputImage, setCropLoader))
    }

    const fetchMedia = (type, setMedia) => {
        let obj = {
            type: type
        }
        dispatch(getUploadData(obj, setMedia))
    }

    const handleSelectImage = (curElem, index) => {
        setState({
            ...state,
            selectImageIndex: index,
            selectVideoIndex: -1
        })
        let objData = {
            url: curElem.url,
            mediaType: "image"
        }
        if (type === "background") {
            dispatch(updateBgMedia(objData, selectedSlideIndex))
        } else {
            if (selectedSlide.layers.length > 0) {
                if (selectedLayerIndex !== -1 && selectedLayer.type !== "text") {
                    dispatch(updateLayerMedia(objData, selectedSlideIndex, selectedLayerIndex))
                } else {
                    dispatch(addLayerMedia(objData, selectedSlideIndex))
                }
            }
            else {
                dispatch(addLayerMedia(objData, selectedSlideIndex))
            }
        }
    }

    const handleSelectVideo = (curElem, index) => {
        setState({
            ...state,
            selectVideoIndex: index,
            selectImageIndex: -1
        })
        let objData = {
            url: curElem.url,
            mediaType: "video"
        }
        if (type === "background") {
            dispatch(updateBgMedia(objData, selectedSlideIndex))
        } else {
            if (selectedSlide.layers.length > 0) {
                if (selectedLayerIndex !== -1 && selectedLayer.type !== "text") {
                    dispatch(updateLayerMedia(objData, selectedSlideIndex, selectedLayerIndex))
                } else {
                    dispatch(addLayerMedia(objData, selectedSlideIndex, selectedLayerIndex))
                }
            } else {
                dispatch(addLayerMedia(objData, selectedSlideIndex, selectedLayerIndex))
            }
        }
    }

    const handleDelete = (e, id, type) => {
        e.stopPropagation()
        setDeleteFile({
            ...deleteFile,
            showDelete: true,
            fileId: id,
            type: type
        })
    }

    const onConfirm = () => {
        setDeleteFile({
            ...deleteFile,
            button: "Deleting..."
        })
        const formData = new FormData()
        formData.append("id", deleteFile.fileId)
        if (deleteFile.type === "images") {
            dispatch(deleteUpload(formData, fetchMedia, deleteFile, setDeleteFile, setImage))
        } else if (deleteFile.type === "video") {
            dispatch(deleteUpload(formData, fetchMedia, deleteFile, setDeleteFile, setVideo))
        }
    }

    const onCancel = () => {
        setDeleteFile({
            ...deleteFile,
            showDelete: false,
            fileId: -1,
            button: "Delete"
        })
    }

    const selectCrop = (url) => {
        setCropModel(true);
        setCurrentImage(url);
        setOutputImage(false)
    }

    useEffect(() => {
        fetchMedia("images", setImage)
        fetchMedia("video", setVideo)
    }, [])

    return (
        <Tab.Pane eventKey="upLoads">
            <div className="videoOpt">

                <div className="scrollDiv scrollDiv-3">

                    <div className="compactUpload mb-2">
                        <div>
                            <div><AiOutlineCloudUpload /></div>
                            <p>Upload Image Max Size 5MB / Video Max Size 20 MB </p>
                        </div>
                        <input
                            type="file"
                            onChange={(e) => handleUpload(e)}
                        />
                    </div>
                    {
                        loader ?
                            <div className="progress mt-3">
                                <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={{
                                        width: `${percent}%`,
                                        color: "black",
                                        fontWeight: 500
                                    }}
                                    aria-valuenow={`${percent}`}
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                >
                                    {percent}%
                                </div>
                            </div>
                            : ""}


                    <div className="imageSelect" >
                        <ul>
                            {image.length > 0 ?
                                image.map((curElem, index) => {
                                    return (
                                        <li className='col-md-6 cursor-pointer' key={index}>

                                            <div className="imageSelect-single">
                                                <img
                                                    src={curElem.url}
                                                    alt={``}
                                                    onClick={() => handleSelectImage(curElem, index)}
                                                />
                                                <AiOutlineDelete
                                                    className='delete-Icon'
                                                    onClick={(e) => handleDelete(e, curElem.id, "images")}
                                                />
                                                {state.selectImageIndex === index ?
                                                    <div className="slide-active">
                                                        <i className="fa fa-check" aria-hidden="true"></i>
                                                    </div>
                                                    : ''
                                                }
                                                <div className="crop-icon"
                                                    onClick={() => selectCrop(curElem.url)}
                                                >
                                                    <i className="fa fa-crop"></i>
                                                </div>
                                            </div>
                                        </li>

                                    )
                                })
                                :
                                ''
                            }
                        </ul>
                        <ul>
                            {video.length > 0 ?
                                video.map((curElem, index) => {
                                    return (
                                        <li
                                            className='col-md-6 cursor-pointer'
                                            key={index}
                                            onClick={() => handleSelectVideo(curElem, index)}
                                            style={{ position: "relative" }}
                                        >
                                            <div className="imageSelect-single">
                                                <HoverVideoPlayer
                                                    className="videoSelect-single"
                                                    style={{ border: '1px solid gray', height: '100%', overflow: 'hidden' }}
                                                    videoSrc={curElem.url}
                                                    pausedOverlay={
                                                        <img src={curElem.poster} alt={curElem.name} width="100%" height="100%" style={{ objectFit: "cover" }} />
                                                    }
                                                    loadingOverlay={
                                                        <div><i className="fa fa-spinner fa-spin hover-loader-center" /></div>
                                                    }
                                                />
                                                <AiOutlineDelete
                                                    className='delete-Icon'
                                                    onClick={(e) => handleDelete(e, curElem.id, "video")}
                                                />
                                                {state.selectVideoIndex === index ?
                                                    <div className="slide-active">
                                                        <i className="fa fa-check" aria-hidden="true"></i>
                                                    </div>
                                                    : ''
                                                }
                                            </div>
                                        </li>

                                    )
                                })
                                :
                                ''
                            }
                        </ul>
                    </div>
                </div>

                <div className={`modal VideoModal ${cropModel ? 'show-modal' : ''} `}>
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header crop-header">
                                <h5 className="modal-title text-center">Crop Image</h5>
                                <button onClick={(e) => setCropModel(false)} type="button" className="close img-close" data-dismiss="modal"
                                    aria-label="Close">
                                    <span aria-hidden="true"><i className="fas fa-times-circle" style={{ color: "#ffffff" }} ></i></span>
                                </button>
                            </div>
                            <div className="modal-body" style={{ position: "relative", height: "50vh" }}>
                                <Cropper
                                    image={currentImage}
                                    crop={crop}
                                    zoom={zoom}
                                    aspect={9 / 16}
                                    onCropChange={setCrop}
                                    onZoomChange={setZoom}
                                    onCropComplete={onCropComplete}
                                    objectFit="contain"
                                />
                                {
                                    croppedAreaPixels === null ? <div className="crop-icon-center"><i className="fa fa-spinner fa-spin mr-2" style={{ color: "#F68802", fontSize: '25px' }} /></div> : ''
                                }

                            </div>
                            <div className="modal-footer d-flex justify-content-between">
                                <button className="btn-custom mr-1" type="button" onClick={() => showCroppedImage()}>
                                    {cropLoader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''}
                                    {cropLoader ? 'Cropping' : 'Crop'}
                                </button>
                                <button
                                    className={`${outputImage === false ? 'btn-custom-disable' : 'btn-custom'}`}
                                    type="button"
                                    disabled={outputImage === false ? true : false}
                                    onClick={() => selectCropImage(outputImage)}
                                >
                                    Use
                                </button>
                            </div>

                        </div>
                    </div>
                </div>


            </div>

            <SweetAlert
                warning
                showCancel
                confirmBtnText={deleteFile.button}
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="light"
                title="Are you sure?"
                onConfirm={(e) => onConfirm(e)}
                onCancel={(e) => onCancel(e)}
                focusCancelBtn
                show={deleteFile.showDelete}
            >
                Do you want to delete this file
            </SweetAlert>
        </Tab.Pane>
    )
}

export default Uploads