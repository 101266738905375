import React from 'react'
import BackgroundPreview from './BackgroundPreview'
import MediaLayer from './MediaLayer'
import TextLayer from './TextLayer'
import '../../temp.css'
import { useDispatch } from 'react-redux'
import { rePositionLayer, resizeLayer } from '../../actions/storyAction'
import PollsLayer from './PollsLayer'


const Preview = ({ selectedSlide, selectedSlideIndex, play }) => {
    let allowTypeMedia = ["image", "video", "gif", "emoji"]
    const dispatch = useDispatch()
    const handleResize = (width, height, left, top, layerIndex) => {
        width = parseInt(width.replace("px", ""))
        height = parseInt(height.replace("px", "")) 
        dispatch(resizeLayer(width, height, left, top, layerIndex, selectedSlideIndex))
    }

    const handleRePosition = (top, left, layerIndex) => {
        dispatch(rePositionLayer(top, left, layerIndex, selectedSlideIndex))
    }
   


    return (
        <div className="vid-poster mt-4">
            <div className="video-container ">
                <BackgroundPreview
                    background={selectedSlide.background}
                    selectedSlideIndex={selectedSlideIndex}
                    selectedSlide={selectedSlide}
                />
                {
                    selectedSlide.layers.length > 0 ?
                        selectedSlide.layers.map((curElem, index) => {
                            if (allowTypeMedia.includes(curElem.type)) {
                                return (
                                    <MediaLayer
                                        index={index}
                                        layer={curElem}
                                        play={play}
                                        selectedSlideIndex={selectedSlideIndex}
                                        selectedSlide={selectedSlide}
                                        handleResize={handleResize}
                                        handleRePosition={handleRePosition}
                                    />
                                )
                            } else if (curElem.type === "polls") {
                                return (
                                    <PollsLayer
                                        index={index}
                                        layer={curElem}
                                        play={play}
                                        selectedSlideIndex={selectedSlideIndex}
                                        selectedSlide={selectedSlide}
                                        handleResize={handleResize}
                                        handleRePosition={handleRePosition}
                                    />
                                )
                            }
                            else {
                                return (
                                    <TextLayer
                                        index={index}
                                        play={play}
                                        layer={curElem}
                                        selectedSlide={selectedSlide}
                                        selectedSlideIndex={selectedSlideIndex}
                                        handleResize={handleResize}
                                        handleRePosition={handleRePosition}
                                    />
                                )
                            }
                        })
                        : ''
                }
            </div>
        </div>

    )
}
export default Preview
