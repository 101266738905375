import React, { useState } from "react";
import Header from './Header'
import EditorLeft from "./left/EditorLeft";
import EditorRight from "./right/EditorRight";
import EditorMid from "./Middel/EditorMid";
import Alert from "../Alert";
import TitleBar from "../TitleBar"
import queryString from "query-string";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchStory, onUnmountStory } from "../../actions/storyAction";
import { ActionCreators } from "redux-undo";


const CreateVideo = ({ location }) => {
    const id = queryString.parse(location.search).id
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const storyData = useSelector(state => state.story)
    const [loader, setLoader] = useState(true)

    const fetchStoryData = () => {
        // dispatch(ActionCreators.clearHistory())
        dispatch(fetchStory(id, auth.token, setLoader))
    }


    useEffect(() => {
        if (id) {
            fetchStoryData()
        }
    }, [id])

    useEffect(() => {
        return () => {
            // setLoader(true)
            // dispatch(onUnmountStory())
        }
    }, [])

    return (
        <>
            <Alert />
            <TitleBar title="Editor" />

            {loader ?
                <div className="loader-sec">
                    <div className="loader">
                    </div>
                </div>
                :
                <div className="editorWrap">
                    <Header
                        name={storyData.name}
                    />
                    <div className="Mainarea">
                        <EditorLeft
                            storyData={storyData}
                        />
                        <EditorMid
                            storyData={storyData}
                        />
                        <EditorRight
                            storyData={storyData}
                        />
                    </div>

                </div>
            }
        </>
    )
}

export default CreateVideo; 