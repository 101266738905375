import React, { useEffect, useState } from 'react';
import { BiMicrophone, BiPauseCircle, BiPlayCircle } from "react-icons/bi"
import { FaRegStopCircle } from "react-icons/fa"
import { AiOutlineCloudUpload, AiOutlineDelete, AiOutlinePlayCircle } from "react-icons/ai";
import { SiAudiomack } from "react-icons/si";
import useRecorder from '../../../MyRecording';
import { useDispatch, useSelector } from 'react-redux';
import { addVoiceOver, getUploadData, removeVoiceOver, uploadFileData } from '../../../../actions/storyAction';
import MusicPlayer from './MusicPlayer';

let newIntervalId, music = false
const Recording = ({ selectedSlide, selectedSlideIndex }) => {
    let [audioURL, status, recorder, startRecording, stopRecording, pauseRecording, resumeRecording, setAudioURL] = useRecorder();

    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const [play, setPlay] = useState(false)
    const [recordingData, setRecordingData] = useState([])
    const [currentMusic, setCurrentMusic] = useState("")
    const [second, setSecond] = useState(0)
    const [t, setT] = useState(false)
    const [audioDuration, setAudioDuration] = useState(0)
    const [state, setState] = useState({
        pickedMusic: -1,
    })
    const [loader, setLoader] = useState(false)
    const [percent, setPercent] = useState(0)

    const [timer, setTimer] = useState({
        h: 0,
        m: 0
    });

    useEffect(() => {
        if (recorder !== null) {
            setT(true)
        }
    }, [recorder])

    const handleClick = (type) => {
        if (type === "start") {
            startRecording()
        }
        if (type === "pause") {
            pauseRecording()
            setT(false)
            clearInterval(newIntervalId);
            return;
        }
        if (type === "resume") {
            resumeRecording()
            setT(true)

        }
        if (type === "stop") {
            stopRecording()
            setT(false)
            clearInterval(newIntervalId);
            let totalTime = timer.h * 3600 + timer.m * 60 + second
            setAudioDuration(totalTime)
            setSecond(0)
            setTimer({
                ...timer,
                h: 0,
                m: 0
            })
            return;
        }

    };
    useEffect(() => {
        if (t) {
            newIntervalId = setInterval(() => {
                setSecond(prevSec => prevSec + 1)
            }, 1000);
        }
    }, [t])


    useEffect(() => {
        if (second > 59) {
            setTimer({
                ...timer,
                m: timer.m + 1
            })
            setSecond(0)
        }
        if (timer.m > 59) {
            setTimer({
                ...timer,
                h: timer.h + 1,
                m: 0
            })
        }
    }, [second])

    // let clearPause
    const handlePlay = () => {
        if (audioURL) {
            const file = URL.createObjectURL(audioURL)
            music = new Audio(file)
            music.play()
            setPlay(true)
        }
    }

    useEffect(() => {
        if (play) {
            setTimeout(() => {
                setPlay(false)
            }, (audioDuration + 1) * 1000)
        }
    }, [play])
    const handlePause = () => {
        // clearTimeout(clearPause)
        music.pause()
        setPlay(false)

    }

    // const removeMedia = (id) => {
    //     let formData = new FormData();
    //     formData.append('id', id);
    //     commonAxios("delete-media-file", formData, dispatch, config)
    //         .then((res) => {
    //             if (res.status) {
    //                 dispatch(setAlert(res.data.message, 'success'));
    //                 fetchRecording();
    //             } else {
    //                 dispatch(setAlert(res.data.message, 'danger'));

    //             }
    //         }).catch((err) => {
    //             dispatch(setAlert('Getting server error', 'danger'));
    //         })
    // }

    const handleUpload = () => {
        const config = {
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded * 100) / total);
                setPercent(percent)
            },
            headers: {
                "Content-Type": "application/json",
                'Authorization': auth.token
            },
        }
        if (audioURL !== '') {
            setLoader({
                ...loader,
                uploadLoader: true
            })
            const fileName = new File([audioURL], "audio.mp3");
            const formData = new FormData();

            formData.append('upload_type', "recording")
            formData.append('file', fileName)
            formData.append('user_id', auth.user.id)

            dispatch(uploadFileData(formData, config, setLoader, setPercent, fetchRecording))
        }
    }

    const fetchRecording = () => {
        const data = { type: 'recording' }
        setLoader({
            ...loader,
            fetchLoader: true
        })
        dispatch(getUploadData(data, setRecordingData))
    }

    useEffect(() => {
        fetchRecording()
    }, [])


    const handleVoiceOver = (obj, index) => {
        if (index === state.pickedMusic) {
            setState({
                ...state,
                pickedMusic: -1
            })
            dispatch(removeVoiceOver(selectedSlideIndex))
        } else {
            setState({
                ...state,
                pickedMusic: index
            })
            dispatch(addVoiceOver(obj, selectedSlideIndex))
        }
    }


    return (
        <div className='videoOpt'>
            <div className='scrollDiv scrollDiv-3'>
                <div className='audioTime'>
                    {
                        timer.h < 10 ? '0' + timer.h : timer.h
                    }
                    :
                    {
                        timer.m < 10 ? '0' + timer.m : timer.m
                    }
                    :
                    {
                        second < 10 ? '0' + second : second
                    }
                </div>
                <div className='imageSelect'>
                    <ul>
                        <li>
                            <div
                                className='musicS'>
                                {status.start ?
                                    !status.pause ?

                                        <BiPauseCircle
                                            fontSize={30}
                                            color="#fff"
                                            className="cursor-pointer"
                                            onClick={() => handleClick("pause")}
                                        /> :
                                        <BiPlayCircle
                                            fontSize={30}
                                            color="#fff"
                                            className="cursor-pointer"
                                            onClick={() => handleClick("resume")}
                                        /> :
                                    <BiMicrophone
                                        fontSize={30}
                                        color="#fff"
                                        onClick={() => handleClick("start")}
                                        className="cursor-pointer"
                                    />
                                }
                            </div>
                        </li>
                        <li>
                            <div className='musicS'>
                                {status.start ?
                                    < FaRegStopCircle
                                        fontSize={30}
                                        color="red"
                                        onClick={() => handleClick("stop")}

                                        className="cursor-pointer"
                                    /> : <FaRegStopCircle
                                        fontSize={30}
                                        color="#fff"
                                    />
                                }
                            </div>
                        </li>
                    </ul>
                </div>
                <div className='row row-alt mb-3'>
                    <div className='col-6'>
                        {play ? <button
                            className='demoLink demoLink-sm btn-block'
                            onClick={handlePause}
                        >Pause</button> :
                            <button
                                className='demoLink demoLink-sm btn-block'
                                onClick={handlePlay}
                            >Play</button>
                        }
                    </div>
                    <div className='col-6'>
                        <button
                            className='demoLink demoLink-sm btn-block'
                            onClick={handleUpload}
                        >
                            {loader.uploadLoader ?
                                <> Uploading  <i className="fa fa-spinner fa-spin mx-1" /></> : 'Upload'
                            }

                        </button>
                    </div>
                </div>
                <hr />
                <div className='imageSelect'>
                    <ul>
                        {recordingData.length > 0 ?
                            recordingData.map((curElem, index) => {
                                let obj = {
                                    url: curElem.url,
                                    title: curElem.name
                                }
                                return (
                                    <li
                                        key={index}
                                        onClick={() => handleVoiceOver(obj, index)}
                                    >
                                        <MusicPlayer
                                            dash={"recording"}
                                            curElem={obj}
                                            currentMusic={currentMusic}
                                            setCurrentMusic={setCurrentMusic}
                                            type={false}
                                            check={state.pickedMusic}
                                            index={index}
                                        />
                                    </li>
                                )
                            })
                            : ""}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Recording;
