import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { doRendering, saveStory, updateName } from '../../actions/storyAction';
import noProfileImage from '../../images/no-profile.png';
import mac from "../../images/fav-new2.png";
import SweetAlert from "react-bootstrap-sweetalert";
import { BsLightningChargeFill, BsFillFileEarmarkZipFill } from "react-icons/bs";
import Dropdown from 'react-bootstrap/Dropdown';
import { setAlert } from '../../actions/alert';
import { logoutUser } from '../../actions/authAction';
import { useEffect } from 'react';

const Header = ({ name }) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const story = useSelector(state => state.story)
    const auth = useSelector(state => state.auth)
    const [storyName, setStoryName] = useState(name)
    const [button, setButton] = useState("Proceed")
    const [showDelete, setShowDelete] = useState(false)
    const [membership, setMembership] = useState([])
    const [alert, setAlert] = useState("")

    const handleChange = (e) => {
        setStoryName(e.target.value)
        dispatch(updateName(e.target.value))
    }

    let handlePreview = (id) => {
        window.open(`https://webstoriesbackend.storyreel.io/preview/${id}`, "_blank")
    }


    const logout = () => {
        dispatch(logoutUser());
    }

    const onConfirm = () => {
        setButton("Proceeding")
        if (alert === "publish") {
            history.push('/publish')
        } else {
            let data = {
                storyId: story.id,
            }
            dispatch(doRendering(data, history, setShowDelete, setButton))
        }
    }

    const handleExport = () => {
        setAlert("export")
        setShowDelete(true)
    }

    const handlePublish = () => {
        setAlert("publish")
        setShowDelete(true)
    }

    useEffect(() => {
        if (auth.user) {
            setMembership(auth.user.membership.split("__"))
        }
    }, [auth.user])

    return (
        <>
            <div className="editHead">
                <div className="editHead-left">
                    <div className="editLogo">
                        <Link to="/dashboard">
                            <img src={mac} alt="logo" />
                        </Link></div>
                    <div className="storyName">
                        <input
                            className="inpEdit"
                            type="text"
                            value={storyName}
                            onChange={(e) => handleChange(e)}
                        />
                    </div>
                </div>
                <div className="editHead-right">
                    <div className="btnDiv">
                        <Link to="/dashboard"><button className="button">Back to Dashboard</button></Link>
                    </div>
                    <div className="btnDiv">
                        <button className="button" onClick={() => handlePreview(story.id)}>Preview</button>
                    </div>
                    <div className="btnDiv hov">
                        <button className="button button-share">Publish Web Stories</button>
                        <div className='btnDivLink'>
                            <div onClick={() => handlePublish()}>
                                <Link to>
                                    <BsLightningChargeFill /> Publish as a Web Story
                                </Link>
                            </div>

                            <div onClick={() => handleExport()}>
                                <Link to>
                                    <BsFillFileEarmarkZipFill /> Render as  a Video <br /> <span>Export static slides as images and animated ones as videos</span>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className='action-nav'>
                        <Dropdown>
                            <Dropdown.Toggle variant="test" id="dropdown-basic">
                                <div className='dropProfileImage'><img src={auth.user ? auth.user.image : noProfileImage} /></div>
                                <div>
                                    <span className='welcometxt'>Welcome</span>
                                    <span className='profileName'>{auth.user?.name}</span>
                                </div>
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="dropdown-menu-dark">

                                {
                                    parseInt(auth.user.is_client_account) === 0 && parseInt(auth.user.is_virtual_account) === 0 ?
                                        <>
                                            <Link className="dropdown-item" to="/profile">
                                                <span className="mr-2"><i className="fas fa-user"></i></span> My Account
                                            </Link>
                                            <Link className="dropdown-item" to="/privacy">
                                                <span className="mr-2"><i className="fas fa-key"></i></span> Privacy Setting
                                            </Link>
                                            {membership.includes("business") || membership.includes("bundle") ?
                                                <Link className="dropdown-item" to="account-management">
                                                    <span className="mr-2"><i className="fas fa-users" ></i></span>
                                                    Account Management
                                                </Link> : ""}
                                        </> : ""}

                                {
                                    parseInt(auth.user.is_client_account) === 0 ?
                                        membership.includes("business") || membership.includes("masterclass") || membership.includes("bundle") ?
                                            <Link className="dropdown-item" to="/upgrades">
                                                <span className="mr-2"><i className="fas fa-plus-square"></i></span> Upgrades
                                            </Link> : "" : ""}


                                <Link className="dropdown-item" to="/help-and-support">
                                    <span className="mr-2"><i className="fas fa-headset"></i></span> Help & Support
                                </Link>
                                <span className="dropdown-divider"></span>
                                <Link className="dropdown-item" onClick={() => logout()}>
                                    <span className="mr-2">
                                        <i className="fas fa-sign-out-alt"></i>
                                    </span> Sign Out
                                </Link>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </div>

            <SweetAlert
                warning
                showCancel
                confirmBtnText={button}
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="secondary"
                title="Do you wish to proceed ?"
                onConfirm={(e) => onConfirm(e)}
                onCancel={(e) => setShowDelete(false)}
                focusCancelBtn
                show={showDelete}
            >
                {alert === "export" ?
                    <>
                        This will export the slides as video and Interactive  Addons like Polls & CTA will not be rendered in the video.
                    </> :
                    <>
                        The background music which you selected will not come on the entire story only audio voices will come.
                    </>
                }
            </SweetAlert>
        </>
    )
}

export default Header