import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import TitleBar from "../TitleBar";
import { useDispatch, useSelector } from "react-redux";
import StoriesCard from "./StoriesCard";
import CreateStoryModule from "../CreateVideos/CreateStoryModule";

const Stories = () => {

  const auth = useSelector(state => state.auth)
  const totalStories = useSelector(state => state.extra.totalStories)
  const [searchKey, setSearchKey] = useState("")
  const [type, setType] = useState("1")
  const [loader, setLoader] = useState(false)
  const [memberShip, setMemberShip] = useState([])

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  useEffect(() => {
    if (auth.user) {
      setMemberShip(auth.user.membership.split("__"))
    }
  }, [auth.user])

  return (
    <>
      <TitleBar title="Projects" />
      <Navbar />

      <section className="siteWrap">

        <div className="projectTitle-wrap">
          <div className="container">
            <div className="projectTitle">
              <div className="projectTitle-left">
                <h2>ALL STORIES | <span>{totalStories} {totalStories >= 2 ? "Stories" : 'Story'}</span> </h2>
              </div>
              <div className="projectTitle-right">
                {/* <select
                                    className="merge-btn"
                                    value={type}
                                    onChange={(e) => handleChangeType(e.target.value)}
                                >
                                    <option value={1}>Video</option>
                                    <option value={2}>Merge Video</option>
                                </select> */}
                <div className="project-input-box">

                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text"><i className="fas fa-search"></i></span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search Story"
                      onChange={(e) => setSearchKey(e.target.value)}
                    />
                  </div>
                </div>
                {auth.user.is_client_account !== "1" ?
                  <button className="demoLink" onClick={handleShow}><i className="fas fa-plus-circle"></i> Create New Story</button> : ""}
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid">

          <div className="forproject">
            <StoriesCard
              searchKey={searchKey}
            />
          </div>

        </div>
      </section>

      <CreateStoryModule
        show={show}
        handleClose={handleClose}
      />
      <Footer />
    </>
  )
}

export default Stories;