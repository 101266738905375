import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import swal from 'sweetalert';
import { makeTts, pollyLanguageFetch, pollyVoiceFetch, updateTtsUrl } from '../../../../actions/storyAction';
import TranslateText from './TranslateText';

let ttsAudio = false;
const VoiceOver = ({ selectedSlide, selectedSlideIndex }) => {
    const dispatch = useDispatch()
    const selectedLayer = selectedSlide.layers.find(({ isSelected }) => isSelected === true)
    const selectedLayerIndex = selectedSlide.layers.findIndex(({ isSelected }) => isSelected === true)
    const [currentTts, setCurrentTts] = useState('');
    const [pollyLanguages, setPollyLanguages] = useState({ data: [] });
    const [pollyVoices, setPollyVoices] = useState({ data: [] });
    const [ttsStatus, setTtsStatus] = useState(true);
    const [engine, setEngine] = useState("")
    const [text, setText] = useState("")
    const [loader, setLoader] = useState(false);
    const [dataToRevert, setDataToRevert] = useState("")

    const [langData, setLangData] = useState({
        lang: '',
        voice: '',
        voiceId: ""
    })
    const [generateData, setGenerateData] = useState({
        url: "",
        duration: 0
    })


    const applyTts = () => {
        dispatch(updateTtsUrl(generateData, langData, selectedSlideIndex, text))
    }

    const playTts = (url) => {
        if (ttsAudio !== false) {
            ttsAudio.pause();
        }
        if (currentTts === url) {
            ttsAudio.pause();
            setCurrentTts('')
        } else {
            ttsAudio = new Audio(url);
            ttsAudio.play();

            ttsAudio.onended = function () {
                setCurrentTts('')
            }
            setCurrentTts(url);
        }
    }

    const generateTts = () => {
        if (text !== "" && langData.lang !== "" && langData.voice !== "") {
            setLoader(true);
            let engineVal
            if (engine === "Neural") {
                engineVal = engine.replace("Neural", "neural")
            }
            else {
                engineVal = engine.replace("Standard", "standard")
            }
            let data = {
                text: text,
                language_id: langData.lang,
                voice_id: langData.voice,
                engine: engineVal
            }

            dispatch(makeTts(data, setGenerateData, setTtsStatus, setLoader, generateData))
        } else {
            swal("Oops!", "Please, add text, language & voice in TTS");
        }
    }

    useEffect(() => {
        if (selectedLayer) {
            if (selectedLayer.type === "text") {
                setText(selectedLayer.src)
            }
        }
    }, [selectedSlide.layers])


    const getLanguage = (e) => {
        setTtsStatus(true)
        setLangData({
            ...langData,
            lang: e.target.value
        })
        fetchPollyVoices(e.target.value);

    }

    const getVoices = (e) => {
        const data = pollyVoices.data.find(({ id }) => id === e.target.value)
        setLangData({
            ...langData,
            voice: data.voice_id,
            voiceId: e.target.value
        })
        setTtsStatus(true)
        setEngine(data.type)
    }


    const handleText = (val) => {
        setTtsStatus(true)
        setText(val)
    }

    const fetchPollyLanguages = () => {
        dispatch(pollyLanguageFetch(setPollyLanguages, pollyLanguages))
    }

    const fetchPollyVoices = (val) => {
        dispatch(pollyVoiceFetch(setPollyVoices, pollyVoices, val, setLangData, langData, setEngine))
    }


    useEffect(() => {
        fetchPollyLanguages();
        fetchPollyVoices();

        return () => {
            if (ttsAudio !== false) {
                ttsAudio.pause();
            }
        }
    }, [])
    return (
        <div className='imgOpt'>
            <div className='scrollDiv scrollDiv-3'>
                <div className='row row-alt'>
                    <div className='col-2'>
                        <input
                            className='inpField'
                            value={selectedSlideIndex + 1}
                            type="text"
                            readOnly
                        />
                        <p className='valTxt'>SLIDE</p>
                    </div>
                    <div className='col-5'>
                        <select
                            className='inpField'
                            id=""
                            onChange={(e) => getLanguage(e)}
                        >
                            <option value="">Languages</option>
                            {
                                pollyLanguages.data.length > 0 ?
                                    pollyLanguages.data.map((item, index) => {
                                        return (
                                            <option key={index} value={item.code}>{item.name}</option>
                                        )
                                    })
                                    : ''
                            }
                        </select>
                        <p className='valTxt'>LANGUAGE</p>
                    </div>
                    <div className='col-5'>
                        <select
                            className='inpField cursor-pointer text-hover-effect'
                            value={langData.voiceId}
                            onChange={(e) => getVoices(e)}
                        >
                            <option value="">Voices</option>
                            {
                                pollyVoices.data.length > 0 ?
                                    pollyVoices.data.map((item, index) => {

                                        return (
                                            <option key={index} value={item.id}>{item.voice_id} ({item.gender}), {item.type}</option>
                                        )
                                    })
                                    : ''
                            }
                        </select>
                        <p className='valTxt'>VOICE</p>
                    </div>
                </div>

                <textarea
                    name=""
                    id=""
                    className='inpField mt-3'
                    value={text}
                    onChange={(e) => handleText(e.target.value)}
                />

                <div className='row row-alt'>
                    <div className='col-6'>
                        {ttsStatus ?
                            <button
                                className='demoLink demoLink-sm btn-block'
                                disabled={loader}
                                onClick={() => generateTts()}
                            >
                                {loader ?
                                    <>
                                        Generating
                                        <i className="fa fa-spinner fa-spin mx-1" />
                                    </> : "Generate"}
                            </button>
                            :
                            <button
                                className='demoLink demoLink-sm btn-block'
                                onClick={() => playTts(generateData.url)}
                            >
                                <span className="mr-1">
                                    <i className={`fas ${currentTts === generateData.url ? 'fa-pause-circle' : 'fa-play-circle'}`} />
                                </span>
                                Preview
                            </button>}
                    </div>
                    <div className='col-6'>
                        <button
                            onClick={() => applyTts()}
                            className={`${ttsStatus ? "disable-button" : ''} demoLink demoLink-sm btn-block`}
                            disabled={ttsStatus}
                        >Save</button>
                    </div>
                </div>
                {/* <hr /> */}

                {/* <TranslateText
                    selectedSlideIndex={selectedSlideIndex}
                    selectedSlide={selectedSlide}
                    setDataToRevert={setDataToRevert}
                /> */}
            </div>
        </div >
    );
}

export default VoiceOver;
