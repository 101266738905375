import React, { useState } from 'react'
import { useEffect } from 'react';
import { AiOutlineCheck, AiOutlineConsoleSql } from "react-icons/ai";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { checkStoryDomain, publishData } from '../../actions/publishAction';

const PublishWithDomain = ({ id }) => {
    const dispatch = useDispatch()
    const publish = useSelector(state => state.publish)
    const [domainToggle, setdomainToggle] = useState(false)
    const [loader, setLoader] = useState(false)
    const [checkDomain, setCheckDomain] = useState({
        url: '',
        active: false,
        loader: false
    })

    const handleDomainToggle = () => {
        setdomainToggle(!domainToggle)
    }

    const handleCheckDomain = () => {
        if (checkDomain.url !== "") {
            setCheckDomain({
                ...checkDomain,
                loader: true
            })
            let data = {
                "domainName": checkDomain.url,
                "storyId": id
            }
            dispatch(checkStoryDomain(data, checkDomain, setCheckDomain))
        }
    }

    const handlePublish = (show) => {
        let data = {
            publish: publish
        }
        dispatch(publishData(data, id, setLoader, show))
    }

    useEffect(() => {
        let data = {
            publish: publish
        }
        dispatch(publishData(data, setLoader))
    }, [publish])

    return (
        <div className="formWrap">
            <div className="profileBlock">
                <h2 class="text-center text-white">Publish Your Story</h2>
                <div className="productBlock align-items-start">
                    <h2 className='text-white pt-5 pb-4'>Custom Domain</h2>
                    <div className='row forCustomdomain'>
                        <div className='col-md-9'>
                            <div className='form-group form-group-alt mt-0'>
                                <label>Custom Domain</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="checkDomain"
                                    placeholder="Enter Custom Domain"
                                    value={checkDomain.url}
                                    onChange={(e) => setCheckDomain({
                                        ...checkDomain,
                                        url: e.target.value
                                    })}
                                />
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <button
                                className="demoLink smBtn mt-0 ml-2"
                                style={checkDomain.url !== "" ? {} : { backgroundColor: "gray" }}
                                onClick={handleCheckDomain}
                            >
                                Add & Publish in Domain
                                {/* Check & Add Custom Domain */}
                                {checkDomain.loader ? <i className="fa fa-spinner fa-spin" /> : ''} </button>
                        </div>
                    </div>
                    {checkDomain.active ?
                        <AiOutlineCheck size={25} color="green" fontWeight={"bold"} className="mx-3 mb-2" /> : ''}
                    <div
                        className="infoTxt cursor-pointer domain-hover-effect text-light pt-3"
                        onClick={() => handleDomainToggle()}
                    >
                        How to Add a Custom Domain
                    </div>
                </div>
                {domainToggle ?
                    <div className="productBlock align-items-start">
                        <div className="productBlock-left"></div>
                        <div className="productBlock-right">
                            <div className="infoTxtGroup">
                                <ul>
                                    <li>Please use top-level domains only (sub-domains won’t work).</li>
                                    <li>Please enter your domain above without any www, http or https (domain.com for example).</li>
                                    <li>Enter your domain name for example "mydomain.com" (without the quotation marks) and click the "Add Domain" button.</li>
                                    <li>Copy this IP address for the A Record: 72.52.158.199</li>
                                    <li>Log into your domain registrar account and find the option to add Host Records, which can usually be found in the Advanced DNS section. If you're struggling to find this, perform a Google search for "[Domain Registrar Here] how to change host records".</li>
                                    <li>Select your type as "A Record".</li>
                                    <li>Set Host as "@".</li>
                                    <li>Paste the copied IP Address from step 4 into the value field.</li>
                                    <li>Leave TTL as Automatic or 14400.</li>
                                    <li>Click Save and you're done.</li>
                                </ul>
                                <p className="redTxt pt-3">Note: It can take up to 48-72 hours to reflect.</p>
                            </div>
                        </div>
                    </div> : ''}



                <div className="form-group form-group-alt text-center">
                    <button
                        className="demoLink mt-0"
                        onClick={() => handlePublish(true)}
                    >{loader ? <> Publishing on StoryReel <i className="fa fa-spinner fa-spin" style={{ color: "#000000", fontSize: "20px" }} /> </> : "Publish on StoryReel"}</button>
                </div>
            </div>
        </div>
    )
}

export default PublishWithDomain