import React, { useEffect, useState } from 'react';
import { AiOutlineDelete, AiOutlinePlayCircle, AiOutlinePauseCircle } from "react-icons/ai";
import { SiAudiomack } from "react-icons/si";
import { GiCheckMark } from "react-icons/gi";
import SweetAlert from 'react-bootstrap-sweetalert';
import { useDispatch } from 'react-redux';
import { deleteUpload } from '../../../../actions/storyAction';

let audio = false
const MusicPlayer = (props) => {
    const dispatch = useDispatch()
    const [duration, setDuration] = useState(0)
    const [state, setState] = useState({
        play: false,
        showDelete: false,
        fileId: -1,
        button: "Delete"
    })
    useEffect(() => {
        let au = document.createElement('audio');
        au.src = props.curElem.url;
        au.addEventListener('loadedmetadata', function () {
            setDuration(Number((au.duration).toFixed(1)));
        }, false);
    }, [])

    props.curElem.duration = duration;
    const handlePlay = (e, music) => {
        e.stopPropagation();
        if (audio === false) {
            audio = new Audio(music)
            audio.play();
        } else {
            audio.pause();
            setState({
                ...state,
                play: false
            })
            audio = new Audio(music)
            audio.play();
        }
        props.setCurrentMusic(music)
        setState({
            ...state,
            play: true,
        })
    }

    const handlePause = (e) => {
        e.stopPropagation();
        if (audio !== false) {
            audio.pause();
            setState({
                ...state,
                play: false
            })
        }
    }

    const handleDelete = (e, id) => {
        e.stopPropagation()
        setState({
            ...state,
            fileId: id,
            showDelete: true
        })
    }
    const onConfirm = () => {
        setState({
            ...state,
            button: "Deleting..."
        })
        const formData = new FormData()
        formData.append('id', state.fileId)
        dispatch(deleteUpload(formData, props.fetchFiles, state, setState))
    }
    const onCancel = () => {
        setState({
            ...state,
            fileId: -1,
            showDelete: false,
            delete: "Delete"
        })
    }

    useEffect(() => {
        return () => {
            if (audio) {
                audio.pause()
            }
            audio = false
        }
    }, [])

    return (
        <div className='audioSingle' style={{ border: props.check === props.index ? "2px solid #ffffff" : "" }} >
            <div className='audioSingle-top cursor-pointer'>
                {props.type === "upload" ?
                    <span className='audioSingle-del'>
                        <AiOutlineDelete
                            onClick={(e) => handleDelete(e, props.curElem.id)}
                        />
                    </span> : ""}

                <span className='audioSingle-dur'>{duration} sec</span>
                {props.check === props.index ? <GiCheckMark style={{ fontSize: "25px", color: "#ffffff" }} /> :
                    <SiAudiomack />}
            </div>
            <div
                className='audioSingle-bot'
            >
                {state.play && props.curElem.url === props.currentMusic ?
                    <AiOutlinePauseCircle
                        onClick={(e) => handlePause(e)}
                        style={{ cursor: 'pointer' }}
                    />
                    :
                    <AiOutlinePlayCircle
                        onClick={(e) => handlePlay(e, props.curElem.url)}
                        style={{ cursor: 'pointer' }}
                    />}
                <span>{props.curElem.title.slice(0, 15)}</span>
            </div>

            <SweetAlert
                warning
                showCancel
                confirmBtnText={state.button}
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="light"
                title="Are you sure?"
                onConfirm={(e) => onConfirm(e)}
                onCancel={(e) => onCancel(e)}
                focusCancelBtn
                show={state.showDelete}
            >
                Do you want to delete this file
            </SweetAlert>


        </div>
    );
}
export default MusicPlayer;
