import React from 'react'
import { FiTrash2 } from "react-icons/fi";
import { GrUndo, GrRedo } from "react-icons/gr";
import { TbBorderAll } from "react-icons/tb";
import { GrPrevious, GrNext } from "react-icons/gr";
import { IoCopyOutline } from "react-icons/io5";
import { useDispatch, useSelector } from 'react-redux';
import { deleteLayer, duplicateLayer, layerDown, layerUp, saveTemplate, updateSaveTemplate } from '../../../actions/storyAction';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import modalCloseIcon from '../../../images/modal-close.png';
// import { ActionCreators as UndoActionCreators } from 'redux-undo'
import { setAlert } from '../../../actions/alert';

const LayerActions = ({ selectedSlide, selectedSlideIndex }) => {
    const dispatch = useDispatch()
    const story = useSelector(state => state.story)
    const selectedLayerIndex = selectedSlide.layers.findIndex(({ isSelected }) => isSelected === true)
    const selectedLayer = selectedSlide.layers.find(({ isSelected }) => isSelected === true)

    const [show, setShow] = useState(false)
    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)

    const [state, setState] = useState({
        updateLoader: false,
        saveLoader: false,
        showField: false,
    })
    const [data, setData] = useState({
        tempName: ""
    })

    const handleDelete = (layerIndex, slideIndex) => {
        if (layerIndex !== -1) {
            if (selectedSlide.layers.length > 0) {
                if (selectedLayer.id !== story.logo.logoId && selectedLayer.id !== story.watermark.watermarkId) {
                    dispatch(deleteLayer(layerIndex, slideIndex))
                }
            }
        }

    }
    const handleDuplicate = () => {
        if (selectedLayerIndex !== -1) {
            if (selectedLayer.id !== story.logo.logoId && selectedLayer.id !== story.watermark.watermarkId) {
                dispatch(duplicateLayer(selectedLayer, selectedSlideIndex))
            }
        }
    }
    const handleLayerChange = (type) => {
        if (selectedLayerIndex !== -1) {
            if (type === "next") {
                if (selectedLayerIndex < selectedSlide.layers.length - 1) {
                    dispatch(layerDown(selectedLayerIndex, selectedSlideIndex))
                }
            } else {
                if (selectedLayerIndex > 0) {
                    dispatch(layerUp(selectedLayerIndex, selectedSlideIndex))
                }
            }
        }
    }

    const handleSave = () => {
        if (selectedSlide.templateType === "inbuilt") {
            setState({
                ...state,
                showField: true
            })
            handleShow()
        }
        if (selectedSlide.templateType === "custome") {
            setState({
                ...state,
                showField: false
            })
            handleShow()
        }
    }

    const saveCustomeTemp = (e) => {
        e.preventDefault()
        setState({
            ...state,
            saveLoader: true
        })
        dispatch(saveTemplate(selectedSlide, data.tempName, state, setState, handleClose))
    }

    const updateCustomeTemp = () => {
        setState({
            ...state,
            updateLoader: true
        })
        dispatch(updateSaveTemplate(selectedSlide, state, setState, handleClose))
    }

    return (
        <>
            <li className="toottip-enabled">
                <button
                    onClick={() => handleDelete(selectedLayerIndex, selectedSlideIndex)}
                >
                    <FiTrash2 />
                </button>
                <span className="toolTip toolTip-left">Delete</span>
            </li>
            <li className="toottip-enabled">
                <button
                    onClick={() => handleDuplicate()}
                >
                    <IoCopyOutline />
                </button>
                <span className="toolTip toolTip-left">Duplicate</span>
            </li>
            <li className="toottip-enabled">
                <button
                    onClick={() => handleLayerChange("next")}
                >
                    <GrNext />
                </button>
                <span className="toolTip toolTip-left">Move Forward</span>
            </li>
            <li className="toottip-enabled">
                <button
                    onClick={() => handleLayerChange("pre")}
                >
                    <GrPrevious />
                </button>
                <span className="toolTip toolTip-left">Move Backward</span>
            </li>
            {/* <li className="toottip-enabled">
                <button
                >
                    <GrUndo />
                </button>
                <span className="toolTip toolTip-left">Undo</span>
            </li>
            <li className="toottip-enabled">
                <button
                >
                    <GrRedo />
                </button>
                <span className="toolTip toolTip-left">Redo</span>
            </li> */}
            <li className="toottip-enabled">
                <button
                    onClick={() => handleSave()}
                >
                    <TbBorderAll />
                </button>
                <span className="toolTip toolTip-left">Save As Template</span>
            </li>


            <Modal className="VideoModal small" show={show} onHide={handleClose} centered>
                <Modal.Body>
                    <div onClick={handleClose} className="vidClose"><img src={modalCloseIcon} /></div>
                    <div className="inpField">
                        {state.showField ?
                            <form className="formSec" onSubmit={(e) => saveCustomeTemp(e)}>
                                <div className="inpField">
                                    <label>Enter Template Name</label>
                                    <input
                                        type="text"
                                        className="inpField-inp"
                                        placeholder="Template Name"
                                        onChange={(e) => setData({
                                            ...data,
                                            tempName: e.target.value
                                        })}
                                        required
                                    />
                                </div>
                                <button type="submit" className="btn-block inpBtn mt-2 create-modal-btn">Save {state.saveLoader ? <i className="fa fa-spinner fa-spin" style={{ fontWeight: "bold" }} /> : ''}</button>
                            </form>

                            :
                            <>
                                <button
                                    type='button'
                                    className="inpField-inp"
                                    style={{ background: "#ffc200", color: "#000000", fontWeight: "bold" }}
                                    onClick={() => setState({
                                        ...state,
                                        showField: true
                                    })}
                                >
                                    Save As New Template

                                </button>
                                <button
                                    type='button'
                                    className="inpField-inp mb-1"
                                    style={{ background: "#ffc200", color: "#000000", fontWeight: "bold" }}
                                    onClick={updateCustomeTemp}
                                >
                                    {state.updateLoader ? <i className="fa fa-spinner fa-spin" style={{ fontWeight: "bold" }} /> : "Update Template"}
                                </button>
                            </>
                        }



                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default LayerActions