import produce from "immer"
const initialState = {
    id: "",
    user_id: "",
    name: "",
    thumbnail: "",
    logo: "",
    watermark: "",
    status: "",
    render_status: "",
    created: "",
    modified: "",
    slides: [],
}

export default function (state = initialState, action) {

    switch (action.type) {
        case "ADD_STORY_DATA":
            return action.payload

        case "UNMOUNT_STORY":
            return false

        case "UPDATE_NAME":
            return produce(state, (draft) => {
                draft.name = action.payload
            })
        case "SLIDE_BACK":
            return produce(state, (draft) => {
                draft.slides[action.payload].isSelected = false
                draft.slides[action.payload - 1].isSelected = true
            })

        case "SLIDE_FORE":
            return produce(state, (draft) => {
                draft.slides[action.payload].isSelected = false
                draft.slides[action.payload + 1].isSelected = true
            })

        case "SLIDE_ADD_BEFORE":
            return produce(state, (draft) => {
                draft.slides[action.payload.index].isSelected = false
                draft.slides.splice(action.payload.index, 0, action.payload.slide)
            })

        case "SLIDE_ADD_AFTER":
            return produce(state, (draft) => {
                draft.slides[action.payload.index].isSelected = false
                draft.slides.splice(action.payload.index + 1, 0, action.payload.slide)
            })

        case "ACTIVE_SLIDE":
            return produce(state, (draft) => {
                draft.slides[action.payload.index].isSelected = true
                draft.slides[action.payload.selectedIndex].isSelected = false
            })

        case "DELETE_SLIDE":
            return produce(state, (draft) => {
                if (action.payload === 0) {
                    draft.slides.splice(action.payload, 1)
                    draft.slides[action.payload].isSelected = true
                }
                else {
                    draft.slides.splice(action.payload, 1)
                    draft.slides[action.payload - 1].isSelected = true
                }
            })

        case "ADD_DUPLICATE_SLIDE":
            return produce(state, (draft) => {
                draft.slides = [...draft.slides, action.payload]
            })

        case "UPDATE_BG_COLOR":
            return produce(state, (draft) => {
                draft.slides[action.payload.index].background = {
                    ...draft.slides[action.payload.index].background,
                    type: "color",
                    src: action.payload.color
                }
            })

        case "UPDATE_BACKGROUND":
            return produce(state, (draft) => {
                draft.slides[action.payload.slideIndex].background = {
                    ...draft.slides[action.payload.slideIndex].background,
                    type: action.payload.obj.mediaType,
                    src: action.payload.obj.url
                }
            })

        case "ADD_MEDIA_LAYER":
            return produce(state, (draft) => {
                draft.slides[action.payload.slideIndex].layers.push(action.payload.layer)
            })

        case "UPDATE_MEDIA_LAYER":
            return produce(state, (draft) => {
                draft.slides[action.payload.slideIndex].layers[action.payload.layerIndex].type = action.payload.obj.mediaType
                draft.slides[action.payload.slideIndex].layers[action.payload.layerIndex].src = action.payload.obj.url
                if (action.payload.obj.mediaType === "video" && !action.payload.obj.url.includes("giphy")) {
                    draft.slides[action.payload.slideIndex].layers[action.payload.layerIndex].mute = true
                }
            })


        case "UPDATE_LAYER_STATUS":
            return produce(state, (draft) => {
                draft.slides[action.payload.slideIndex].layers.forEach((curElem, index) => {
                    if (index === action.payload.newLayerIndex) {
                        curElem.isSelected = true
                    }
                    else {
                        curElem.isSelected = false
                    }
                })
            })

        case "DELETE_LAYER":
            return produce(state, (draft) => {
                draft.slides[action.payload.slideIndex].layers.splice(action.payload.layerIndex, 1)
                if (draft.slides[action.payload.slideIndex].layers.length > 0) {
                    draft.slides[action.payload.slideIndex].layers[0].isSelected = true
                }

            })

        case "UPDATE_TEXT_FONTSTYLE":
            return produce(state, (draft) => {
                let textArrfFamily = [...draft.slides[action.payload.selectedSlideIndex].layers]
                textArrfFamily[action.payload.selectedTextIndex].style = {
                    ...textArrfFamily[action.payload.selectedTextIndex].style,
                    fontFamily: action.payload.data
                }
            })


        case "UPDATE_TEXT_FONTSIZE":
            return produce(state, (draft) => {
                let textArrfSize = [...draft.slides[action.payload.selectedSlideIndex].layers]
                textArrfSize[action.payload.selectedTextIndex].style = {
                    ...textArrfSize[action.payload.selectedTextIndex].style,
                    fontSize: parseInt(action.payload.data)
                }
            })



        case "UPDATE_TEXT_FONTBOLD":
            return produce(state, (draft) => {
                let textArrfBold = [...draft.slides[action.payload.selectedSlideIndex].layers]
                textArrfBold[action.payload.selectedTextIndex].style = {
                    ...textArrfBold[action.payload.selectedTextIndex].style,
                    fontWeight: action.payload.data
                }
            })


        case "UPDATE_TEXT_FONTITALIC":
            return produce(state, (draft) => {
                let textArrfItalic = [...draft.slides[action.payload.selectedSlideIndex].layers]
                textArrfItalic[action.payload.selectedTextIndex].style = {
                    ...textArrfItalic[action.payload.selectedTextIndex].style,
                    fontStyle: action.payload.data
                }
            })

        case "UPDATE_TEXT_FONTTRANSFORM":
            return produce(state, (draft) => {
                let textArrfTrans = [...draft.slides[action.payload.selectedSlideIndex].layers]
                textArrfTrans[action.payload.selectedTextIndex].style = {
                    ...textArrfTrans[action.payload.selectedTextIndex].style,
                    textTransform: action.payload.data
                }
            })

        case "UPDATE_TEXT_FONTDECO":
            return produce(state, (draft) => {
                let textArrfDeco = [...draft.slides[action.payload.selectedSlideIndex].layers]
                textArrfDeco[action.payload.selectedTextIndex].style = {
                    ...textArrfDeco[action.payload.selectedTextIndex].style,
                    textDecoration: action.payload.data
                }
            })

        case "UPDATE_TEXT_FONTALIGN":
            return produce(state, (draft) => {
                let textArrfAlign = [...draft.slides[action.payload.selectedSlideIndex].layers]
                textArrfAlign[action.payload.selectedTextIndex].style = {
                    ...textArrfAlign[action.payload.selectedTextIndex].style,
                    textAlign: action.payload.data
                }

            })


        case "UPDATE_TEXT_FONTLINEHEIGHT":
            return produce(state, (draft) => {
                let textArrfLheight = [...draft.slides[action.payload.selectedSlideIndex].layers]
                textArrfLheight[action.payload.selectedTextIndex].style = {
                    ...textArrfLheight[action.payload.selectedTextIndex].style,
                    lineHeight: action.payload.data
                }
            })


        case "UPDATE_TEXT_FONTOPACITY":
            return produce(state, (draft) => {
                let textArrfOpacity = [...draft.slides[action.payload.selectedSlideIndex].layers]
                textArrfOpacity[action.payload.selectedTextIndex].style = {
                    ...textArrfOpacity[action.payload.selectedTextIndex].style,
                    opacity: action.payload.data
                }

            })

        case "UPDATE_TEXT_FONTCOLOR":
            return produce(state, (draft) => {
                let textArrfColor = [...draft.slides[action.payload.selectedSlideIndex].layers]
                textArrfColor[action.payload.selectedTextIndex].style = {
                    ...textArrfColor[action.payload.selectedTextIndex].style,
                    color: action.payload.data
                }
            })


        case "UPDATE_TEXT_FONTBGCOLOR":
            return produce(state, (draft) => {
                let textArrfBgColor = [...draft.slides[action.payload.selectedSlideIndex].layers]
                textArrfBgColor[action.payload.selectedTextIndex].style = {
                    ...textArrfBgColor[action.payload.selectedTextIndex].style,
                    backgroundColor: action.payload.data
                }
            })


        case "REMOVE_TEXT_FONTBGCOLOR":
            return produce(state, (draft) => {
                let textArrfRBgColor = [...draft.slides[action.payload.selectedSlideIndex].layers]
                textArrfRBgColor[action.payload.selectedTextIndex].style = {
                    ...textArrfRBgColor[action.payload.selectedTextIndex].style,
                    backgroundColor: ""
                }
            })


        case "UPDATE_LAYER_TEXT":
            return produce(state, (draft) => {
                let selectedLayerLayer1 = [...draft.slides[action.payload.slideIndex].layers]
                selectedLayerLayer1[action.payload.layerIndex].src = action.payload.text
                if (action.payload.type) {
                    selectedLayerLayer1[action.payload.layerIndex].type = action.payload.type
                }

            })


        case "DUPLICATE_LAYER":
            return produce(state, (draft) => {
                draft.slides[action.payload.slideIndex].layers.forEach((curElem) => {
                    curElem.isSelected = false
                })
                draft.slides[action.payload.slideIndex].layers.push(action.payload.newLayer)
            })


        case "LAYER_DOWN":
            return produce(state, (draft) => {
                let layersArr3 = [...draft.slides[action.payload.slideIndex].layers]
                let temp = layersArr3[action.payload.layerIndex]
                layersArr3[action.payload.layerIndex] = layersArr3[action.payload.layerIndex + 1]
                layersArr3[action.payload.layerIndex + 1] = temp
                draft.slides[action.payload.slideIndex].layers = layersArr3
            })


        case "LAYER_UP":
            return produce(state, (draft) => {
                let layersArr4 = [...draft.slides[action.payload.slideIndex].layers]
                let temp11 = layersArr4[action.payload.layerIndex]
                layersArr4[action.payload.layerIndex] = layersArr4[action.payload.layerIndex - 1]
                layersArr4[action.payload.layerIndex - 1] = temp11
                draft.slides[action.payload.slideIndex].layers = layersArr4
            })


        case "ADD_TEXT_LAYER":
            return produce(state, (draft) => {
                let selectedLayerLayer = [...draft.slides[action.payload.slideIndex].layers]
                selectedLayerLayer.forEach(layer => {
                    layer.isSelected = false
                })
                selectedLayerLayer = [...selectedLayerLayer, action.payload.layer]
                draft.slides[action.payload.slideIndex].layers = selectedLayerLayer
            })



        case "RESIZE_LAYER":
            return produce(state, (draft) => {
                let layersArr1 = [...draft.slides[action.payload.slideI].layers]
                layersArr1[action.payload.layerI].size = {
                    ...layersArr1[action.payload.layerI].size,
                    width: action.payload.w,
                    height: action.payload.h
                }
                layersArr1[action.payload.layerI].position = {
                    ...layersArr1[action.payload.layerI].position,
                    left: action.payload.l,
                    top: action.payload.t
                }
            })


        case "REPOSITION_LAYER":
            return produce(state, (draft) => {
                let layersArr2 = [...draft.slides[action.payload.slideI].layers]
                layersArr2[action.payload.layerI].position = {
                    ...layersArr2[action.payload.layerI].position,
                    left: action.payload.l,
                    top: action.payload.t
                }
            })


        case "UPDATE_ANIMATION_LAYER":
            return produce(state, (draft) => {
                if (draft.slides[action.payload.slideIndex].layers[action.payload.layerIndex].animation.name === "") {
                    draft.slides[action.payload.slideIndex].layers[action.payload.layerIndex].animation = action.payload.obj
                } else {
                    draft.slides[action.payload.slideIndex].layers[action.payload.layerIndex].animation = {
                        ...draft.slides[action.payload.slideIndex].layers[action.payload.layerIndex].animation,
                        name: action.payload.obj.name,
                        ampName: action.payload.obj.ampName,
                        playAnimation: action.payload.obj.playAnimation
                    }
                }
            })

        case "REMOVE_ANIMATION":
            return produce(state, (draft) => {
                if (draft.slides[action.payload.slideIndex].layers[action.payload.layerIndex].animation.name === "") {
                    draft.slides[action.payload.slideIndex].layers[action.payload.layerIndex].animation = action.payload.obj
                } else {
                    draft.slides[action.payload.slideIndex].layers[action.payload.layerIndex].animation = {
                        ...draft.slides[action.payload.slideIndex].layers[action.payload.layerIndex].animation,
                        name: "",
                        ampName: "",
                        playAnimation: false,
                    }
                }
            })


        case "UPDATE_ANIMATION_DURATION":
            return produce(state, (draft) => {
                draft.slides[action.payload.slideIndex].layers[action.payload.layerIndex].animation = {
                    ...draft.slides[action.payload.slideIndex].layers[action.payload.layerIndex].animation,
                    duration: action.payload.duration,
                    playAnimation: true
                }
            })


        case "REMOVE_ANIMATION_DURATION":
            return produce(state, (draft) => {
                draft.slides[action.payload.slideIndex].layers[action.payload.layerIndex].animation = {
                    ...draft.slides[action.payload.slideIndex].layers[action.payload.layerIndex].animation,
                    duration: "",
                    playAnimation: true
                }
            })



        case "UPDATE_ANIMATION_DELAY":
            return produce(state, (draft) => {
                draft.slides[action.payload.slideIndex].layers[action.payload.layerIndex].animation = {
                    ...draft.slides[action.payload.slideIndex].layers[action.payload.layerIndex].animation,
                    delay: action.payload.delay,
                    playAnimation: true
                }
            })



        case "REMOVE_ANIMATION_DELAY":
            return produce(state, (draft) => {
                draft.slides[action.payload.slideIndex].layers[action.payload.layerIndex].animation = {
                    ...draft.slides[action.payload.slideIndex].layers[action.payload.layerIndex].animation,
                    delay: "",
                    playAnimation: true
                }
            })

        case "PLAY_ANIMATION":
            return produce(state, (draft) => {
                draft.slides[action.payload].layers.forEach((curElem) => {
                    curElem.animation = {
                        ...curElem.animation,
                        playAnimation: true
                    }
                })
            })


        case "END_ANIMATION":
            return produce(state, (draft) => {
                draft.slides[action.payload.slideIndex].layers[action.payload.layerIndex].animation = {
                    ...draft.slides[action.payload.slideIndex].layers[action.payload.layerIndex].animation,
                    playAnimation: false
                }
            })

        case "UNSELECT_LAYERS":
            return produce(state, (draft) => {
                draft.slides[action.payload].layers.forEach((curElem) => {
                    curElem.isSelected = false
                })
            })


        case "ADD_BORDER":
            return produce(state, (draft) => {
                let layer18 = [...draft.slides[action.payload.slideIndex].layers]
                layer18[action.payload.layerIndex].style = {
                    ...layer18[action.payload.layerIndex].style,
                    border: action.payload.border
                }
            })




        case "ADD_SHADOW":
            return produce(state, (draft) => {
                let layer19 = [...draft.slides[action.payload.slideIndex].layers]
                layer19[action.payload.layerIndex].style = {
                    ...layer19[action.payload.layerIndex].style,
                    boxShadow: action.payload.shadowData
                }
            })


        case "ADD_FILTER":
            return produce(state, (draft) => {
                let layer20 = [...draft.slides[action.payload.slideIndex].layers]
                layer20[action.payload.layerIndex].filter = {
                    ...layer20[action.payload.layerIndex].filter,
                    name: action.payload.filterData
                }
            })


        case "UPDATE_LAYER_BORDER":
            return produce(state, (draft) => {
                let layer21 = [...draft.slides[action.payload.slideIndex].layers]
                layer21[action.payload.layerIndex].style = {
                    ...layer21[action.payload.layerIndex].style,
                    border: action.payload.borderClass
                }
            })

        case "USE_INBUILT_TEMP":
            return produce(state, (draft) => {
                let tempObj = JSON.parse(action.payload.tempData.data)
                draft.slides[action.payload.slideIndex] = {
                    ...draft.slides[action.payload.slideIndex],
                    background: tempObj.background,
                    layers: tempObj.layers,
                    templateId: action.payload.tempData.id,
                    templateType: action.payload.type
                }
            })

        case "UPDATE_LOCATION":
            return produce(state, (draft) => {
                draft.slides[action.payload.slideIndex].layers[action.payload.layerIndex].src = action.payload.loc
            })

        case "UPDATE_GENERATED_TTS":
            return produce(state, (draft) => {
                draft.slides[action.payload.slideIndex].audio = action.payload.voiceOver
            })

        case 'UPDATE_SLIDE_DURATION':
            return produce(state, (draft) => {
                const selectedTile = draft.slides.findIndex(({ isSelected }) => isSelected === true)
                draft.slides[selectedTile].duration = parseInt(action.payload)
            })

        case "ADD_BG_MUSIC":
            return produce(state, (draft) => {
                draft.bg_audio = {
                    ...draft.bgAudio,
                    enable: true,
                    type: "mp3",
                    source: action.payload.url,
                    duration: action.payload.duration,
                    volume: 50
                }
            })

        case "REMOVE_BG_MUSIC":
            return produce(state, (draft) => {
                draft.bg_audio = {
                    ...draft.bgAudio,
                    enable: false,
                    type: "mp3",
                    source: "",
                    duration: 0,
                    volume: 50
                }
            })

        case "REMOVE_VOICEOVER":
            return produce(state, (draft) => {
                draft.slides[action.payload].audio = {
                    ...draft.slides[action.payload].audio,
                    enable: false,
                    type: "mp3",
                    src: "",
                    volume: 100,
                    meta: {
                        text: "",
                        languageId: "",
                        voiceId: "",
                        translateText: ""
                    }
                }
            })


        case "UPDATE_WATERMARK_STATUS":
            return produce(state, (draft) => {
                if (action.payload) {
                    draft.watermark = {
                        ...draft.watermark,
                        enable: action.payload
                    }
                } else {
                    draft.slides.forEach((curElem) => {
                        let watermarkLayerIndex = curElem.layers.findIndex(({ id }) => id === draft.watermark.watermarkId)
                        if (watermarkLayerIndex !== -1) {
                            curElem.layers.splice(watermarkLayerIndex, 1)
                        }
                    })
                    draft.watermark = {
                        ...draft.watermark,
                        enable: action.payload,
                        pos: "top-left",
                        watermarkId: ""
                    }
                }
            })

        case "UPDATE_WATERMARK":
            return produce(state, (draft) => {
                draft.slides.forEach((curElem) => {
                    curElem.layers.push(action.payload)
                })
                draft.watermark = {
                    watermarkId: action.payload.id,
                    enable: true,
                    pos: "top-left"
                }
            })
        case "UPDATE_WATERMARK_POS":
            return produce(state, (draft) => {
                if (action.payload === "top-left") {
                    draft.slides.forEach((curElem) => {
                        let watermarkLayerIndex = curElem.layers.findIndex(({ id }) => id === draft.watermark.watermarkId)
                        if (watermarkLayerIndex !== -1) {
                            curElem.layers[watermarkLayerIndex].position = {
                                top: 0,
                                left: 0
                            }
                        }
                    })
                } else if (action.payload === "top-center") {
                    draft.slides.forEach((curElem) => {
                        let watermarkLayerIndex = curElem.layers.findIndex(({ id }) => id === draft.watermark.watermarkId)
                        if (watermarkLayerIndex !== -1) {
                            curElem.layers[watermarkLayerIndex].position = {
                                top: 0,
                                left: 140
                            }
                        }
                    })
                }
                else if (action.payload === "top-right") {
                    draft.slides.forEach((curElem) => {
                        let watermarkLayerIndex = curElem.layers.findIndex(({ id }) => id === draft.watermark.watermarkId)
                        if (watermarkLayerIndex !== -1) {
                            curElem.layers[watermarkLayerIndex].position = {
                                top: 0,
                                left: 280
                            }
                        }
                    })
                }
                else if (action.payload === "bottom-left") {
                    draft.slides.forEach((curElem) => {
                        let watermarkLayerIndex = curElem.layers.findIndex(({ id }) => id === draft.watermark.watermarkId)
                        if (watermarkLayerIndex !== -1) {
                            curElem.layers[watermarkLayerIndex].position = {
                                top: 560,
                                left: 0
                            }
                        }
                    })
                }
                else if (action.payload === "bottom-center") {
                    draft.slides.forEach((curElem) => {
                        let watermarkLayerIndex = curElem.layers.findIndex(({ id }) => id === draft.watermark.watermarkId)
                        if (watermarkLayerIndex !== -1) {
                            curElem.layers[watermarkLayerIndex].position = {
                                top: 560,
                                left: 140
                            }
                        }
                    })
                }
                else if (action.payload === "bottom-right") {
                    draft.slides.forEach((curElem) => {
                        let watermarkLayerIndex = curElem.layers.findIndex(({ id }) => id === draft.watermark.watermarkId)
                        if (watermarkLayerIndex !== -1) {
                            curElem.layers[watermarkLayerIndex].position = {
                                top: 560,
                                left: 280
                            }
                        }
                    })
                }
            })


        case "UPDATE_LOGO_STATUS":
            return produce(state, (draft) => {
                if (action.payload) {
                    draft.logo = {
                        ...draft.logo,
                        enable: action.payload
                    }
                } else {
                    draft.slides.forEach((curElem) => {
                        let logoLayerIndex = curElem.layers.findIndex(({ id }) => id === draft.logo.logoId)
                        if (logoLayerIndex !== -1) {
                            curElem.layers.splice(logoLayerIndex, 1)
                        }
                    })
                    draft.logo = {
                        ...draft.logo,
                        enable: action.payload,
                        pos: "top-left",
                        logoId: ""
                    }
                }
            })

        case "UPDATE_LOGO":
            return produce(state, (draft) => {
                draft.slides.forEach((curElem) => {
                    curElem.layers.push(action.payload)
                })
                draft.logo = {
                    logoId: action.payload.id,
                    enable: true,
                    pos: "top-left"
                }
            })
        case "UPDATE_LOGO_POS":
            return produce(state, (draft) => {
                if (action.payload === "top-left") {
                    draft.slides.forEach((curElem) => {
                        let logoLayerIndex = curElem.layers.findIndex(({ id }) => id === draft.logo.logoId)
                        if (logoLayerIndex !== -1) {
                            curElem.layers[logoLayerIndex].position = {
                                top: 0,
                                left: 0
                            }
                        }
                    })
                } else if (action.payload === "top-center") {
                    draft.slides.forEach((curElem) => {
                        let logoLayerIndex = curElem.layers.findIndex(({ id }) => id === draft.logo.logoId)
                        if (logoLayerIndex !== -1) {
                            curElem.layers[logoLayerIndex].position = {
                                top: 0,
                                left: 140
                            }
                        }
                    })
                }
                else if (action.payload === "top-right") {
                    draft.slides.forEach((curElem) => {
                        let logoLayerIndex = curElem.layers.findIndex(({ id }) => id === draft.logo.logoId)
                        if (logoLayerIndex !== -1) {
                            curElem.layers[logoLayerIndex].position = {
                                top: 0,
                                left: 280
                            }
                        }
                    })
                }
                else if (action.payload === "bottom-left") {
                    draft.slides.forEach((curElem) => {
                        let logoLayerIndex = curElem.layers.findIndex(({ id }) => id === draft.logo.logoId)
                        if (logoLayerIndex !== -1) {
                            curElem.layers[logoLayerIndex].position = {
                                top: 560,
                                left: 0
                            }
                        }
                    })
                }
                else if (action.payload === "bottom-center") {
                    draft.slides.forEach((curElem) => {
                        let logoLayerIndex = curElem.layers.findIndex(({ id }) => id === draft.logo.logoId)
                        if (logoLayerIndex !== -1) {
                            curElem.layers[logoLayerIndex].position = {
                                top: 560,
                                left: 140
                            }
                        }
                    })
                }
                else if (action.payload === "bottom-right") {
                    draft.slides.forEach((curElem) => {
                        let logoLayerIndex = curElem.layers.findIndex(({ id }) => id === draft.logo.logoId)
                        if (logoLayerIndex !== -1) {
                            curElem.layers[logoLayerIndex].position = {
                                top: 560,
                                left: 280
                            }
                        }
                    })
                }
            })

        case "UPDATE_DURATION":
            return produce(state, (draft) => {
                draft.slides[action.payload.slideIndex].duration = action.payload.dur
            })

        case "UPDATE_AI_VOLUME":
            return produce(state, (draft) => {
                draft.slides[action.payload.slideIndex].audio.volume = action.payload.vol
            })

        case "UPDATE_BG_VOLUME":
            return produce(state, (draft) => {
                draft.bg_audio.volume = action.payload
            })

        case "ADD_LAYER_LINK":
            return produce(state, (draft) => {
                draft.slides[action.payload.slideIndex].layers[action.payload.layerIndex].url = action.payload.link
            })

        case "ADD_CTA_LAYER":
            return produce(state, (draft) => {
                let selectedLayerLayer = [...draft.slides[action.payload.slideIndex].layers]
                selectedLayerLayer.forEach(layer => {
                    layer.isSelected = false
                })
                draft.slides[action.payload.slideIndex].layers = [...draft.slides[action.payload.slideIndex].layers, action.payload.layer]
            })

        case "CHANGE_VIDEO_MUTE":
            return produce(state, (draft) => {
                draft.slides[action.payload.slideIndex].layers[action.payload.layerIndex].mute = true
            })

        case "CHANGE_VIDEO_UNMUTE":
            return produce(state, (draft) => {
                draft.slides[action.payload.slideIndex].layers[action.payload.layerIndex].mute = false
            })

        case "ADD_POLLS":
            return produce(state, (draft) => {
                let selectedLayerLayer = [...draft.slides[action.payload.slideIndex].layers]
                selectedLayerLayer.forEach(layer => {
                    layer.isSelected = false
                })
                draft.slides[action.payload.slideIndex].layers.push(action.payload.layer)
            })

        case "UPDATE_POLL_QUESTION":
            return produce(state, (draft) => {
                draft.slides[action.payload.slideIndex].layers[action.payload.layerIndex].src.question = action.payload.value
            })
        case "UPDATE_POLL_OPTION1":
            return produce(state, (draft) => {
                draft.slides[action.payload.slideIndex].layers[action.payload.layerIndex].src.option1 = action.payload.value
            })
        case "UPDATE_POLL_OPTION2":
            return produce(state, (draft) => {
                draft.slides[action.payload.slideIndex].layers[action.payload.layerIndex].src.option2 = action.payload.value
            })
        case "UPDATE_POLL_OPTION3":
            return produce(state, (draft) => {
                draft.slides[action.payload.slideIndex].layers[action.payload.layerIndex].src.option3 = action.payload.value
            })
        case "UPDATE_POLL_OPTION4":
            return produce(state, (draft) => {
                draft.slides[action.payload.slideIndex].layers[action.payload.layerIndex].src.option4 = action.payload.value
            })
        default:
            return state

    }
}

